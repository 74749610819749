import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
// import { TextAreaField } from "src/components/form";
import { Form, message } from "antd";

import { Button } from "src/components";
import RejectModalStyle from "./RejectModalStyle";
import { useAppDispatch } from "src/stores";
import { rejectAppointmentRequests } from "src/stores/screens/appointment-request/appointment-request.action";

interface RejectModalProps {
  isModalRejectOpen: boolean;
  request: any;
  handleOk: () => void;
  handleCancel: () => void;
}

const RejectModal: React.FC<RejectModalProps> = ({
  isModalRejectOpen,
  request,
  handleOk,
  handleCancel
}) => {
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    // reason: yup.string().required("Vui lòng nhập lý do")
  });

  const initialValues = {
    reason: ""
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: () => {
      handleSubmit();
    }
  });

  const handleSubmit = async () => {
    const res: any = await dispatch(
      rejectAppointmentRequests({ data: { appointmentRequestId: request.id } })
    );
    if (res?.error) {
      message.error("Từ chối khách hàng đặt lịch thất bại");
    } else {
      message.success("Từ chối khách hàng đặt lịch thành công");
      handleOk();
    }
  };

  const onClose = () => {
    handleCancel();
    formik.resetForm({});
  };

  // const { setFieldValue } = formik;

  return (
    <RejectModalStyle
      title="Từ chối đặt lịch"
      visible={isModalRejectOpen}
      onOk={handleOk}
      onCancel={onClose}
      className="modal-reject"
    >
      <Form
        name="reason"
        className="setting-form"
        layout="vertical"
        onFinish={() => formik.handleSubmit()}
      >
        {/* <TextAreaField
          field={formik.getFieldProps("reason")}
          setFieldValue={setFieldValue}
          className="form-control form-reason"
          placeholder="Nhập lý do..."
          error={formik.errors.reason}
          touched={formik.touched.reason}
        /> */}
        <p className="text-content">Bạn xác nhận từ chối với lịch hẹn này?</p>
        <div className="button-footer">
          <Button buttonType="cancel" onClick={onClose} width={100}>
            Đóng
          </Button>
          <Button htmlType="submit" width={100}>
            Đồng ý
          </Button>
        </div>
      </Form>
    </RejectModalStyle>
  );
};

export default RejectModal;
