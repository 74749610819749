import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const SETTING_API = {
  listSettingAPI: async (params: any) => {
    const response = await new AxiosClient().get(`/common/busy-time?${new URLSearchParams(params).toString()}`);
    return get(response, 'data', null);
  },
  createSettingAPI: async (params) => {
    const response = await new AxiosClient().post('/common/busy-time', params);
    const data = get(response, 'data', null);
    return data;
  },
  deleteSettingAPI: async (id) => {
    const response = await new AxiosClient().delete('/common/busy-time/' + id);
    const data = get(response, 'data', null);
    return data;
  },
};

export default SETTING_API;
