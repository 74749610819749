import React, { useState, useEffect } from "react";
import { formatDateDDMMYYYY } from "src/utils";
import UserManagementStyle from "../user_management/UserManagementStyle";
import { Table, TableProps, message } from "antd";
import { Button, Empty, Pagination } from "src/components";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import DeleteModal from "./delete-modal/DeleteModal";
import FormModal from "./form-modal/FormModal";
import { getListNews, deleteNew } from "src/stores/screens/new/new.action";

import { useAppDispatch, useAppSelector } from "src/stores";
import { useNavigate } from "react-router-dom";
import { SpinStyle } from "src/components/style";

const NewsScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { listNews, isLoading } = useAppSelector((state) => state.new);

  const columns: TableProps<any>["columns"] = [
    {
      title: "Ngày đăng",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      onCell: () => ({ style: { minWidth: 150 } }),
      render: (createdAt) => {
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatDateDDMMYYYY(createdAt)}
          </span>
        );
      }
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      width: 200,
      onCell: () => ({ style: { minWidth: 200 } }),
      render: (title) => {
        return (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "250px",
              whiteSpace: "nowrap"
            }}
          >
            {title}
          </div>
        );
      }
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      key: "content",
      width: 300,
      onCell: () => ({ style: { minWidth: 300 } }),
      render: (content) => {
        const textOnly = content.replace(/<[^>]*>?/gm, "");
        return (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "450px",
              whiteSpace: "nowrap"
            }}
            dangerouslySetInnerHTML={{ __html: textOnly }}
          />
        );
      }
    },
    {
      title: "Action",
      key: "action",
      width: 130,
      onCell: () => ({ style: { minWidth: 130 } }),
      render: (_, record) => (
        <div className="action-box">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setUser(record);
              setIsModalDeleteOpen(true);
            }}
          >
            <DeleteOutlined />
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onEditUser(record);
            }}
          >
            <EditOutlined />
          </Button>
        </div>
      )
    }
  ];

  const initUser = {
    username: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: 0
  };

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [user, setUser] = useState<User.UserType>(initUser);
  const [filter, setFilter] = useState({ title: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = (page = 1) => {
    const params = cleanFilter({ ...filter, page: page });
    void dispatch(getListNews({ data: params }));
    setCurrentPage(page);
  };

  const cleanFilter = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === "") {
        delete obj[key];
      }
    });
    return obj;
  };

  const onEditUser = (user: User.UserType) => {
    navigate(`/new/update/${user?.id}`);
  };

  const handleDeleteOk = async () => {
    const result = await dispatch(deleteNew({ data: user.id }));
    setIsModalDeleteOpen(false);
    if (result) {
      message.success("Xóa tin tức thành công");
      fetchNews();
    } else {
      message.success("Xóa tin tức thất bại");
    }
  };

  const handleRowClick = (user: User.UserType) => {
    setUser(user);
    setIsModalInfoOpen(true);
  };

  const handleOkFormUser = () => {
    fetchNews();
    setIsModalFormOpen(false);
  };

  const onChangePage = (value: number) => {
    fetchNews(value);
  };

  return (
    <UserManagementStyle className="container">
      <SpinStyle
        spinning={isLoading}
        wrapperClassName="root-spin"
        className="root-spin-component"
      >
        <div className="header-content">
          <h2 className="title">Quản lý tin tức</h2>
          {!!listNews?.data?.length && (
            <Button
              className="create-user"
              onClick={() => navigate("/news/create")}
            >
              Tạo tin tức
            </Button>
          )}
        </div>
        <div className="content">
          {listNews?.data?.length ? (
            <>
              <div className="data-table">
                <Table
                  dataSource={listNews?.data || []}
                  columns={columns}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: () => handleRowClick(record)
                    };
                  }}
                />
                <Pagination
                  total={listNews?.total}
                  onChange={onChangePage}
                  current={currentPage}
                  pageSize={10}
                />
              </div>
            </>
          ) : (
            <Empty
              className="empty-content"
              isShowCreate
              contentText="Chưa có tin tức nào trong danh sách"
              buttonText="Tạo tin tức"
              onCreate={() => navigate("/news/create")}
            />
          )}
        </div>
        <DeleteModal
          isModalDeleteOpen={isModalDeleteOpen}
          handleCancel={() => setIsModalDeleteOpen(false)}
          handleOk={() => handleDeleteOk()}
        />
        <FormModal
          isModalFormOpen={isModalFormOpen}
          user={user}
          handleCancel={() => setIsModalFormOpen(false)}
          handleOk={handleOkFormUser}
        />
      </SpinStyle>
    </UserManagementStyle>
  );
};

export default NewsScreen;
