import React from "react";
import { TagComponentStyle } from "./tagStyle";

interface timeComponentProps {
  children?: any;
  color?: string;
  className?: string;
  style?: any
}

const TagComponent: React.FC<timeComponentProps> = ({
  children,
  className,
  style,
  ...props
}) => {
  return (
    <TagComponentStyle className={className} {...props} style={style}>{children}</TagComponentStyle>
  );
};

export default TagComponent;
