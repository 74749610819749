import React from "react";
import { Col, Row } from "antd";
import { Button, Image, Tag } from "src/components";
import InfoModalStyle from "./FormInfoStyle";
import { DEFAULT_AVATAR } from "src/assets/images";
import { ATTRIBUTES, GENDER } from "src/constants";
import { formatDateDDMMYYYY, getLunarYearName } from "src/utils";

interface InfoModalProps {
  isVisibleModal: boolean;
  user: User.UserType;
  handleCancel: () => void;
  onOpenModalEdit?: () => void;
  onOpenModalDelete?: () => void;
}

const InfoModal: React.FC<InfoModalProps> = ({
  isVisibleModal,
  user,
  handleCancel,
  onOpenModalEdit,
  onOpenModalDelete
}) => {
  const getNameAttribute = (item) => {
    return `${ATTRIBUTES.find((i) => i.value === item)?.label}`;
  };

  const getGender = (num: number) => {
    return GENDER.find((i) => i.value === num)?.label || "";
  };

  const getNameYear = (dateString: string) => {
    if(!dateString) return ""
    const date = new Date(dateString);
    const year = date.getFullYear();
    return getLunarYearName(year);
  };

  return (
    <InfoModalStyle
      title={"Chi tiết người dùng"}
      visible={isVisibleModal}
      onCancel={handleCancel}
      className="modal-info"
      width={700}
    >
      <Row>
        <Col className="col-avatar" span={8}>
          <Image src={user?.avatarUrl || DEFAULT_AVATAR} className="avatar" />
        </Col>
        <Col className="col-info" span={16}>
          <div className="info-user">
            {user?.attribute && (
              <Tag className={user?.attribute} style={{ marginBottom: "10px" }}>
                Mệnh {getNameAttribute(user?.attribute)}
              </Tag>
            )}

            {/* <Tag className="moc" style={{ marginBottom: "10px" }}>
              Thiếu mộc
            </Tag> */}
            <div className="info-item">
              <p className="label">Họ và tên</p>
              <p className="value">{user?.username}</p>
            </div>
            <div className="info-item">
              <p className="label">Email</p>
              <p className="value">{user?.email}</p>
            </div>
            <div className="info-item">
              <p className="label">Số điện thoại</p>
              <p className="value">{user?.phoneNumber}</p>
            </div>
            <div className="info-item">
              <p className="label">Ngày sinh</p>
              <p className="value">
                {user?.dateOfBirthLunar
                  ? `${formatDateDDMMYYYY(
                      user?.dateOfBirthLunar
                    )} (${getNameYear(user?.dateOfBirthLunar)})`
                  : ""}
              </p>
            </div>
            <div className="info-item">
              <p className="label">Giới tính</p>
              <p className="value">{getGender(user?.gender)}</p>
            </div>
            <div className="info-item">
              <p className="label">Link Facebook</p>
              <a
                className="link"
                target="_blank"
                href={user.facebookId || ""}
                rel="noreferrer"
              >
                {user.facebookId}
              </a>
            </div>
            <div className="info-item">
              <p className="label">Thiếu mệnh</p>
              {user.missingAttributes ? (
                <div className="missing-attributes">
                  {user.missingAttributes.map((i: any) => (
                    <Tag
                      className={i.attribute}
                      style={{ marginBottom: "10px" }}
                    >
                      Thiếu {getNameAttribute(i.attribute)}
                    </Tag>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <div className="button-footer">
        <Button buttonType="cancel" onClick={onOpenModalDelete} width={100}>
          Xóa
        </Button>
        <Button width={100} onClick={onOpenModalEdit}>
          Chỉnh sửa
        </Button>
      </div>
    </InfoModalStyle>
  );
};

export default InfoModal;
