import React, { useState } from "react";
import {
  TeamOutlined,
  SettingOutlined,
  CalendarOutlined,
  UnorderedListOutlined,
  FileTextOutlined
} from "@ant-design/icons";
import { Menu, Layout } from "antd";
import styled from "styled-components";
import { Link, useOutlet } from "react-router-dom";
import Media from "src/utils/media";
import { Image, Dropdown } from "src/components";
import { DEFAULT_AVATAR, LOGO } from "src/assets/images";
import { logout } from "src/utils/auth";
import { LOCAL_STORAGE_KEY } from "src/constants";

const { Header, Content, Sider } = Layout;

const PrivateLayout: React.FC = () => {
  const outlet = useOutlet();
  const [activeRoute, setActiveRoute] = useState<string>("");

  const MENU = [
    {
      key: "SETTING_APPOINTMENT",
      icon: <SettingOutlined />,
      label: <Link to="/setting_appointment">Cài đặt lịch hẹn</Link>,
      path: "/setting_appointment"
    },
    {
      key: "LIST OF REQUIREMENTS",
      icon: <UnorderedListOutlined />,
      label: <Link to="/list_of_requirements">Danh sách yêu cầu</Link>,
      path: "/list_of_requirements"
    },
    {
      key: "APPOINTMENT",
      icon: <CalendarOutlined />,
      label: "Lịch hẹn tư vấn",
      children: [
        {
          key: "APPOINTMENT/DAY",
          label: <Link to="/appointment/day"> Theo ngày</Link>,
          path: "/appointment/day"
        },
        {
          key: "APPOINTMENT/YEAR",
          label: <Link to="/appointment/year">Theo năm</Link>,
          path: "/appointment/year"
        },
        {
          key: "APPOINTMENT_MESSAGES",
          label: <Link to="/appointment_messages">Tin nhắn mới nhất</Link>,
          path: "/appointment_messages"
        }
      ]
    },
    {
      key: "USER MANAGEMENT",
      icon: <TeamOutlined />,
      label: <Link to="/user_management">Quản lý người dùng</Link>,
      path: "/user_management"
    },
    {
      key: "NEWS",
      icon: <FileTextOutlined />,
      label: <Link to="/news">Quản lý tin tức</Link>,
      path: "/news"
    }
  ];

  const handleClick = (e: any) => {
    setActiveRoute(e.key);
  };

  const items = [
    {
      key: "1",
      label: <div className="lable">Đăng xuất</div>,
      onClick: () => logout()
    }
  ];

  const email = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL);

  return (
    <PrivateLayoutStyle>
      <Header className="body-header">
        <HeaderStyle>
          <Image src={LOGO} />
          <UserInfoStyle>
            {
              <div className="header-info">
                <Dropdown
                  trigger={
                    <div className="user-name">
                      <Image width={24} src={DEFAULT_AVATAR} />
                      <div>{email}</div>
                    </div>
                  }
                  items={items}
                  placement="bottomRight"
                />
              </div>
            }
          </UserInfoStyle>
        </HeaderStyle>
      </Header>
      <Layout className="layout-body">
        <Sider
          className="layout-sider"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(_) => {}}
          onCollapse={(_) => {}}
        >
          <MenuStyle>
            <Menu
              mode="inline"
              className="layout-menu"
              defaultSelectedKeys={[MENU[0].key]}
              selectedKeys={[activeRoute]}
              items={MENU}
              onClick={handleClick}
            />
          </MenuStyle>
        </Sider>
        <Content className="body-content">{outlet}</Content>
      </Layout>
    </PrivateLayoutStyle>
  );
};

export default PrivateLayout;

const PrivateLayoutStyle = styled(Layout)`
  min-height: 100vh;
  width: 100%;

  .body-header {
    background: linear-gradient(197.35deg, #2d2621 18.51%, #261d12 94.71%);
    border-bottom: 1px solid rgb(67 61 61);
  }

  .layout-body {
    .body-content {
      /* width: 100%; */
      margin: 0 auto;
      padding: 20px 80px;
      background: #222222;
      background-size: cover;
      background-position: center;
      background-attachment: scroll;
    }
    .ant-layout-sider-zero-width-trigger {
      /* background-color: transparent; */
      /* top: 0px; */
    }

    .body-footer {
      text-align: center;
    }
    ${Media.lessThan(Media.SIZE.MD)} {
      .body-content {
        padding: 20px;
      }
    }

    .layout-sider {
      background: #1d1b18;
    }
  }
`;

const HeaderStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const UserInfoStyle = styled.div`
  text-align: right;
  .header-info {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .lable {
      height: 18px;
    }
    .ant-image {
      height: 48px;
    }
    img {
      cursor: pointer;
    }
    .user-name {
      display: flex;
      gap: 6px;
      font-weight: 700;
      font-size: 14px;
      color: #ead38e;
      cursor: pointer;
    }
  }
`;

const MenuStyle = styled.div`
  .layout-menu {
    height: 100%;
    padding: 0;
    background: #1d1b18;
    border: none;
    .ant-menu-item .ant-menu-item-icon {
      color: #ffffff;
    }
    .ant-menu-item {
      .ant-menu-title-content {
        a {
          color: #ffffff;
        }
      }
      &::after {
        border: none;
      }
    }
    .ant-menu-item-selected {
      background-color: #efd484 !important;
      .ant-menu-item-icon {
        color: #000000;
      }
      .ant-menu-title-content {
        a {
          color: #000000;
          text-decoration: none;
        }
      }
    }
    .ant-menu-submenu {
      background-color: #1d1b18;
      .ant-menu-submenu-title {
        color: #ffffff;
        .ant-menu-submenu-arrow {
          color: #ffffff;
        }
      }
      .ant-menu-sub {
        background-color: #1d1b18;
        color: #ffffff;
      }
    }
    .ant-menu-item:hover,
    .ant-menu-submenu-title:hover {
      opacity: 0.8;
    }
  }
`;
