import React from "react";
import { Col, Form, Row, message } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  RadioGroupField,
  InputField,
  PasswordField,
  SelectField
} from "src/components/form";
import { Button } from "src/components";
import AvatarUploader from "./uploadAvatar";
import FormModalStyle from "./FormModalStyle";
import { ATTRIBUTES, GENDER, CAN_CHI, DAY, MONTH } from "src/constants";

import { useAppDispatch } from "src/stores";
import { createUser, updateUser } from "src/stores/screens/user/user.action";

interface FormModalProps {
  isModalFormOpen: boolean;
  user: User.UserType;
  handleCancel: () => void;
  handleOk: () => void;
}

const FormModal: React.FC<FormModalProps> = ({
  isModalFormOpen,
  user,
  handleCancel,
  handleOk
}) => {
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    username: yup.string().required("Họ tên là bắt buộc"),
    email: yup.string().email().required("Email là bắt buộc"),
    phoneNumber: yup
      .string()
      .required("Số điện thoại là bắt buộc")
      .matches(/^\d+$/, "Số điện thoại không hợp lệ"),
    password: yup.string().required("Mật khẩu là bắt buộc").min(8)
  });

  const initialValues = {
    username: user?.username || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    password: user?.password || "",
    dateOfBirthLunar: user?.dateOfBirthLunar || "",
    attribute: user?.attribute || null,
    gender: user?.gender || 0,
    missingAttributes: user.missingAttributes || [],
    dateLunar: user?.dateLunar || "",
    monthLunar: user?.monthLunar || "",
    yearLunar: user?.yearLunar || ""
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleSubmit(value);
    }
  });

  const handleSubmit = async (value: User.UserType) => {
    const params = {
      ...value,
      dateOfBirthLunar: `${value.dateLunar} - ${value.monthLunar} - ${value.yearLunar}`,
      role: "user"
    };
    const result = user.id
      ? await dispatch(updateUser({ data: params }))
      : await dispatch(createUser({ data: params }));
    console.log("result", result);
    if (result) {
      message.success(
        user.id ? "Cập nhật người dùng thành công" : "Tạo mới dùng thành công"
      );
      handleOk();
    } else {
      message.success(
        user.id ? "Cập nhật người dùng thất bại" : "Tạo mới dùng thất bại"
      );
    }
  };

  const onClose = () => {
    formik.resetForm({});
    handleCancel();
  };

  const { setFieldValue } = formik;

  return (
    <FormModalStyle
      title={user.id ? "Cập nhật người dùng" : "Tạo người dùng"}
      visible={isModalFormOpen}
      onCancel={onClose}
      className="modal-form"
      width={700}
    >
      <Form
        name="login"
        className="setting-form"
        layout="vertical"
        onFinish={(value) => {
          formik.handleSubmit();
        }}
      >
        <Row>
          <Col span={8}>
            <AvatarUploader />
          </Col>
          <Col span={16}>
            <InputField
              label="Họ và tên"
              field={formik.getFieldProps("username")}
              setFieldValue={setFieldValue}
              className="form-control form-username"
              inputProps={{
                size: "middle",
                placeholder: "Nhập họ và tên"
              }}
              error={formik.errors.username}
              touched={formik.touched.username}
              required
            />
            <InputField
              label="Email"
              field={formik.getFieldProps("email")}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: "middle",
                placeholder: "Nhập email"
              }}
              error={formik.errors.email}
              touched={formik.touched.email}
              required
            />
            <InputField
              label="Số điện thoại"
              field={formik.getFieldProps("phoneNumber")}
              setFieldValue={setFieldValue}
              className="form-control form-phoneNumber"
              inputProps={{
                size: "middle",
                placeholder: "Nhập số điện thoại"
              }}
              error={formik.errors.phoneNumber}
              touched={formik.touched.phoneNumber}
              required
            />
            <div className="birthdayLunar">
              <p className="label">Ngày sinh âm lịch</p>
              <div className="box-birthday">
                <SelectField
                  optionsSelect={DAY.map((item) => ({
                    value: item,
                    label: item
                  }))}
                  field={formik.getFieldProps("dateLunar")}
                  setFieldValue={setFieldValue}
                  className="form-control birthdayLunar-item dateLunar"
                  style={{ width: "120px" }}
                />
                <SelectField
                  optionsSelect={MONTH.map((item) => ({
                    value: item,
                    label: item
                  }))}
                  field={formik.getFieldProps("monthLunar")}
                  setFieldValue={setFieldValue}
                  className="form-control birthdayLunar-item monthLunar"
                  style={{ width: "120px" }}
                />
                <SelectField
                  optionsSelect={CAN_CHI.map((item) => ({
                    value: item,
                    label: item
                  }))}
                  field={formik.getFieldProps("yearLunar")}
                  setFieldValue={setFieldValue}
                  className="form-control birthdayLunar-item yearLunar"
                  style={{ width: "120px" }}
                />
              </div>
            </div>
            <RadioGroupField
              label="Giới tính"
              field={formik.getFieldProps("gender")}
              setFieldValue={setFieldValue}
              className="form-control form-gender"
              error={formik.errors.gender}
              touched={formik.touched.gender}
              optionsSelect={GENDER}
              required
            />
            <SelectField
              label="Mệnh"
              optionsSelect={ATTRIBUTES}
              field={formik.getFieldProps("attribute")}
              setFieldValue={setFieldValue}
              className="form-control form-attribute"
              error={formik.errors.attribute}
              touched={formik.touched.attribute}
              placeholder="Chọn Mệnh"
            />
            <SelectField
              label="Thiếu Mệnh"
              mode="tags"
              optionsSelect={ATTRIBUTES}
              field={formik.getFieldProps("missingAttributes")}
              setFieldValue={setFieldValue}
              className="form-control form-missing-attribute"
              error={formik.errors.missingAttributes}
              touched={formik.touched.missingAttributes}
              placeholder="Chọn Thiếu Mệnh"
            />
            <PasswordField
              label="Mật khẩu"
              field={formik.getFieldProps("password")}
              setFieldValue={setFieldValue}
              className="form-control form-password"
              inputProps={{
                size: "middle",
                placeholder: "Mật khẩu"
              }}
              error={formik.errors.password}
              touched={formik.touched.password}
              required
            />
          </Col>
        </Row>
        <div className="button-footer">
          <Button buttonType="cancel" onClick={onClose} width={100}>
            Đóng
          </Button>
          <Button htmlType="submit" width={100}>
            {user.id ? "Cập nhật" : "Tạo mới"}
          </Button>
        </div>
      </Form>
    </FormModalStyle>
  );
};

export default FormModal;
