import React, { useState } from "react";
import { Col, Form, Layout, Row, message } from "antd";
import { Button } from "src/components";
import { Image } from "src/components";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { LARGE_LOGO } from "src/assets/images";
import { useAppDispatch } from "src/stores";
import { InputField } from "src/components/form";
import { loginAction } from "src/stores/screens/auth/auth.action";

const { Content } = Layout;

const LoginScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email('Email không hợp lệ').required("Vui lòng nhập email"),
    password: yup.string().required("Vui lòng nhập mật khẩu").min(8, 'Mật khẩu phải có ít nhất 8 ký tự')
  });

  const initialValues = {
    email: "",
    password: ""
  };

  const onLoginSuccess = (): void => {
    navigate("/setting_appointment");
  };

  const handleLogin = async (value: any) => {
    // set to bypass login, remove later
    // localStorage.setItem('email', value.email);
    // localStorage.setItem('token', value.email);
    // onLoginSuccess();

    const res: any = await dispatch(loginAction({ data: value, callback: onLoginSuccess }));
    if (res?.error) {
      message.error("Đăng nhập không thành công")
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleLogin(value);
    }
  });

  const { setFieldValue } = formik;

  return (
    <LoginScreenStyle>
      <Row gutter={16} style={{ height: "100%" }}>
        <Col
          xs={24}
          sm={13}
          className="box-logo"
        >
          <div className="logo">
            <Image src={LARGE_LOGO} />
          </div>
          <div className="title">
            <p className="brand-name">Phong Thủy Nhật Minh</p>
            <p className="description">
              Đặt lịch tư vấn, xem quẻ, luận giải về phong thủy
            </p>
          </div>
        </Col>
        <Col xs={24} sm={11} className="box-form">
          <Form
            name="login"
            className="login-form"
            onFinish={() => formik.handleSubmit()}
          >
            <p className="title">Đăng nhập</p>
            {/* <p className="sub-title">
              Chưa có tài khoản?
              <Link to={"/register"}> Đăng ký ngay</Link>
            </p> */}
            <InputField
              label="Email"
              field={formik.getFieldProps("email")}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: "middle",
                placeholder: "Nhập Email"
              }}
              error={formik.errors.email}
              touched={formik.touched.email}
            />

            <InputField
              label="Mật khẩu"
              field={formik.getFieldProps("password")}
              setFieldValue={setFieldValue}
              className="form-control form-password"
              inputProps={{
                size: "middle",
                placeholder: "Nhập mật khẩu",
                type: isShowPassword ? "text" : "password"
              }}
              error={formik.errors.password}
              touched={formik.touched.password}
              suffix={
                <div
                  onClick={() => setIsShowPassword(!isShowPassword)} // Toggle trạng thái
                  style={{ cursor: "pointer" }}
                >
                  {isShowPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              }
            />

            <Button className="btn-submit" htmlType="submit">
              Đăng nhập
            </Button>
          </Form>
          {/* <Link to={"/forgot_password"} className="forgot-password">
            Quên mật khẩu
          </Link> */}
        </Col>
      </Row>
    </LoginScreenStyle>
  );
};

export default LoginScreen;

const LoginScreenStyle = styled(Content)`
  @import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-row {
    height: 100%;
    .ant-form-item-row {
      flex-direction: column;
    }
    .ant-form-item-label > label {
      display: flex;
      color: #ead38e;
    }
  }

  @media only screen and (max-width: 1024px) {
    .box-logo {
      display: none !important;
    }
    .box-form {
      min-width: 100%;
    }
  }

  .box-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: "100%";
    background-size: "cover";
    background-position: "center";
    background-repeat: "no-repeat";
    padding: 50px;
    color: #ead38e;
    text-align: center;
    font-family: "Patrick Hand";
    .brand-name {
      font-size: 40px;
      line-height: 0;
    }
    .description {
      font-size: 28px;
    }
    .ant-image-img {
      height: 30%;
      width: 30%;
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 8% !important;
    background-color: #242424;
    .title {
      font-size: 32px;
      color: #ead38e;
      font-weight: bold;
    }
    .sub-title {
      font-size: 17px;
      color: #ffffff;
      a {
        color: red !important;
      }
    }
    .forgot-password {
      margin-top: 10px;
      display: flex;
      justify-content: end;
    }
  }

  .login-form {
    width: 100%;
    .form-password input.ant-input {
      border: none;
    }
  }

  .btn-submit {
    width: 100%;
  }
`;
