import { createAsyncThunk } from '@reduxjs/toolkit';

import NEW_API from './new.api';

export const getListNews = createAsyncThunk<any, any>(
  'new',
  async ({ data }, { rejectWithValue }) => {
    try {
      return NEW_API.listNewsAPI(data);
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const createNew = createAsyncThunk<any, any>(
  'create_new',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await NEW_API.createNewsAPI(data);
      if (result) {
        callback();
      }
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
export const updateNew = createAsyncThunk<any, any>(
  'update_new',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await NEW_API.updateNewsAPI(data);
      if (result) {
        callback();
      }
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const deleteNew = createAsyncThunk<any, any>(
  'delete_new',
  async ({ data }, { rejectWithValue }) => {
    try {
      return NEW_API.deleteNewsAPI(data);
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const detailNew = createAsyncThunk<any, any>(
  'detail_new',
  async ({ data }, { rejectWithValue }) => {
    try {
      return NEW_API.detailNewsAPI(data);
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
