import React from 'react';
import { PaginationComponentStyle } from './paginationStyle';

interface paginationComponentProps {
  total?: number;
  className?: string;
  onChange?: (value: number) => void;
  current?: number;
  pageSize?: number;
}

const Pagination: React.FC<paginationComponentProps> = ({ total, className, onChange, current, pageSize }) => {
  return (
    <PaginationComponentStyle
      className={className}
      showLessItems
      total={total}
      onChange={onChange}
      current={current}
      pageSize={pageSize}
    />
  );
};

export default Pagination;
