/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { getConsultationsAction, createConsultationAction, pinConsultationAction } from './consultation.action';

const initialState: Consultation.ConsultationState = {
  isLoading: false,
  error: null,
  messages: {
    total: 0,
    page: 1,
    limit: 10,
    data: [],
  },
};

const { actions, reducer } = createSlice({
  name: 'auth_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getConsultationsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getConsultationsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        if (String(action.payload?.page) === '1') {
          state.messages = {
            total: action.payload.total,
            page: action.payload.page,
            limit: action.payload.limit,
            data: action.payload?.data.reverse(),
          };
        } else {
          state.messages = {
            total: action.payload.total,
            page: action.payload.page,
            limit: action.payload.limit,
            data: [...action.payload?.data.reverse(), ...state.messages.data],
          };
        }
      })
      .addCase(getConsultationsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(createConsultationAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createConsultationAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createConsultationAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(pinConsultationAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(pinConsultationAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(pinConsultationAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
