import React from 'react';
import { Image as ImageAnt } from 'antd';
import styled from 'styled-components';

interface CommonImageProps {
  src: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  preview?: boolean;
  className?: string;
}

const Image: React.FC<CommonImageProps> = ({
  src,
  alt = 'image',
  width,
  height,
  preview = false,
  className,
}) => {
  return <ImageStyle className={className}><ImageAnt src={src} alt={alt} width={width} height={height} preview={preview} /></ImageStyle>;
};

export default Image;

const ImageStyle = styled.div`
  height: 100%;
  width: auto;
`;
