import React, { useState } from "react";
import { CardStyle } from "src/utils/styled";
import { ConsultationStyle } from "./ConsultationStyle";
import StatusModal from "./status-modal/StatusModal";
import ChatDetail from "./chats/ChatDetail";
import { Button, Image } from "src/components";
import { DEFAULT_AVATAR } from "src/assets/images";
import { LeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Col, Row } from "antd";

const InterpretationScreen: React.FC = () => {
  const { id } = useParams();

  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false);

  return (
    <ConsultationStyle className="container">
      <Row gutter={16} className="info-bar" justify="space-between">
        <Col xs={24} sm={24} md={8}className="box-user">
          <LeftOutlined
            className="btn-back"
            onClick={() => window.history.back()}
          />
          <Image
            className="avatar"
            width={30}
            height={30}
            src={DEFAULT_AVATAR}
          />
          <div className="username">Cooper Kristin</div>
        </Col>
        <Col xs={12} sm={12} md={8} className="status">Đã khởi tạo</Col>
        <Col xs={12} sm={12} md={8} className="btn-status">
          <Button onClick={() => setIsModalStatusOpen(true)}>
            Thay đổi trạng thái
          </Button>
        </Col>
      </Row>
      <CardStyle title="Luận giải - Thứ 5, 12/09/2024" className="custom-card">
        <ChatDetail id={id} />
      </CardStyle>
      <StatusModal
        isModalStatusOpen={isModalStatusOpen}
        handleCancel={() => setIsModalStatusOpen(false)}
        id={id}
      />
    </ConsultationStyle>
  );
};

export default InterpretationScreen;
