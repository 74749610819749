import React, { useRef } from 'react';
import { Form, Button as ButtonAnt } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import Image from 'src/components/images';
import { ICON_IMAGE, ICON_SEND } from 'src/assets/images';

const QuillEditorStyle = styled.div`
  width: 100%;
  border-top: 1px solid #745812;
  .chat-box {
    margin-bottom: 0px;
  }
  .quill-editor-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    .custom-editor {
      width: 100%;
      overflow: auto;
      max-height: 160px;
      min-height: 60px;
      padding-top: 10px;
      &::-webkit-scrollbar {
        width: 5px;
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #745811;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #715e2d;
      }
    }
  }

  .quill-editor-wrapper .ql-container {
    width: 100%;
    border: none;
    .ql-editor,
    .ql-editor.ql-blank::before {
      color: #ffffff;
      margin-right: 65px;
    }
  }

  .quill-upload-button {
    margin-left: 10px;
  }
  .btn-group {
    display: flex;
    position: absolute;
    right: 12px;
    bottom: 15px;
    .custom-btn {
      background: none;
      border: none;
    }
  }
`;

type Props = {
  label?: string;
  field: FieldInputProps<any>;
  setFieldValue: (field: any, value: any) => any;
  placeholder?: string;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  disable?: boolean;
} & Omit<FormItemProps, 'children'>;

const QuillEditor: React.FC<Props> = ({
  label,
  touched,
  error,
  field,
  setFieldValue,
  placeholder,
  disable,
  ...rest
}) => {
  const quillRef = useRef<ReactQuill | null>(null);

  const handleChange = (value: string): void => {
    setFieldValue(field.name, value);
  };

  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          const quillEditor = quillRef.current?.getEditor();
          const range = quillEditor?.getSelection();
          quillEditor?.insertEmbed(range?.index ?? 0, 'image', e.target?.result);
        };
        reader.readAsDataURL(file);
      }
    };
  };

  return (
    <QuillEditorStyle>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={error && touched ? 'error' : ''}
        help={(touched && error) as string}
        {...rest}
      >
        <div className="quill-editor-wrapper">
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={field.value}
            onChange={handleChange}
            placeholder={placeholder}
            readOnly={disable ?? false}
            modules={{
              toolbar: false,
              clipboard: {
                matchVisual: false,
              },
            }}
            formats={['image']}
            className="custom-editor"
          />
          <div className="btn-group">
            <ButtonAnt className="custom-btn" icon={<Image src={ICON_IMAGE} />} onClick={handleImageUpload} />
            <ButtonAnt className="custom-btn" icon={<Image src={ICON_SEND} />} htmlType="submit" />
          </div>
        </div>
      </Form.Item>
    </QuillEditorStyle>
  );
};

export default QuillEditor;
