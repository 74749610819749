import { createSlice } from '@reduxjs/toolkit';
import { listAppointmentRequests, updateAppointmentRequests, deleteAppointmentRequests, approveAppointmentRequests, rejectAppointmentRequests, listAppointmentRequestsFilter } from './appointment-request.action';

const initialState: AppointmentRequest.AppointmentState = {
  isLoading: false,
  error: null,
  listRequests: [],
  listRequestsFilter: []
};

const { actions, reducer } = createSlice({
  name: 'appointment_request_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(listAppointmentRequests.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(listAppointmentRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listRequests = action.payload;
      })
      .addCase(listAppointmentRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(updateAppointmentRequests.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateAppointmentRequests.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateAppointmentRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(deleteAppointmentRequests.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteAppointmentRequests.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteAppointmentRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(approveAppointmentRequests.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveAppointmentRequests.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(approveAppointmentRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(rejectAppointmentRequests.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(rejectAppointmentRequests.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(rejectAppointmentRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(listAppointmentRequestsFilter.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(listAppointmentRequestsFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        const newData = action.payload?.data.map((item: any) => {
          const children = item?.requests?.map((request: any) => ({
            ...request,
            isChild: true
          }));

          return { ...item, children: children, key: item.user.id, isParent: true };

        });
        state.listRequestsFilter = { data: newData, total: action.payload?.total };
      })
      .addCase(listAppointmentRequestsFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
  },
});

export { reducer };
export default actions;
