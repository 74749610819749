import { createSlice } from '@reduxjs/toolkit';
import { getListSetting, createSetting, deleteSetting} from './setting.action';

const initialState: any = {
  isLoading: false,
  error: null,
  listSettings: [],
};

const { actions, reducer } = createSlice({
  name: 'new_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListSetting.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListSetting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listSettings = action.payload;
      })
      .addCase(getListSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(createSetting.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createSetting.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(deleteSetting.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteSetting.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

  },
});

export { reducer };
export default actions;
