import styled from 'styled-components';

const NewsStyle = styled.div`
  .header-content {
    display: flex;
    justify-content: space-between;

    .title {
      color: #EAD38E;
    }
  }
  .content {
    height: 720px;
    border: 1px solid #745811;
    border-radius: 5px;
    padding: 24px;
    .ant-row {
      height: 100%;
      flex-direction: column;
      .ant-form-item-row {
        flex-direction: column !important;
      }
      .ant-form-item-label > label {
        display: flex;
        color: #ead38e;
      }
    }
    .filter-box {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .search-box {
        display: flex;
        .ant-input-affix-wrapper {
          .ant-input-prefix {
            color: #342C24;
          }
          border: 1px solid #342C24;
          background-color: #272727;
          .ant-input {
            background-color: #272727;
            color: #ffffff;
          }
        }
      }
      .ant-select {
        .ant-select-selector {
          background-color: #272727;
          border: 1px solid #342C24;
          /* max-height: 32px;
          white-space: nowrap;
          overflow: hidden; */
          text-overflow: ellipsis;
          .ant-select-selection-item, input::placeholder {
            color: #ffffff;
          }
        }
        .ant-select-arrow {
          color: #ffffff;
        }
      }
      .ant-select-multiple {
        .ant-select-selection-item {
          background-color: #EAD38E;
          border: none;
          color: black !important;
          .ant-select-selection-item-remove {
            color: black !important;
          }
        }
      }
      .box-switch {
        display: flex;
        gap: 10px;
        height: 100%;
        margin: auto 0;
        span {
          color: #ffffff
        }
        .ant-switch {
          background: #6A6A6A;
        }
        .ant-switch-checked {
          background: #EAD38E;
        }
      }
    }
    .data-table {
      margin-top: 30px;
      min-height: calc(100vh - 300px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .ant-table-wrapper {
        .ant-table {
          .ant-table-thead {
            th {
              background-color: #322B1C;
              color: #ffffff;
              border: none;
            }
          }
          .ant-table-tbody {
            td {
              background-color: #222222;
              border: none;
              color: #ffffff;
              .name-box {
                display: flex;
                gap: 10px;
                .ant-image-img {
                  border-radius: 9999px !important;
                }
                p {
                  margin: auto 0;
                }
              }
            }
            .action-box {
              display: flex;
              gap: 5px;
            }
          }
        }
      }
    }
  }

`;

export default NewsStyle;
