import { DatePicker } from 'antd';
import styled from 'styled-components';

export const DatePickerStyled = styled(DatePicker)`
  &.ant-picker {
    background-color: #272727;
    border: 1px solid #342C24;
    .ant-picker-panel-container {
      border: 1px solid #745811;
      background-color: #272727;
    }
    input {
      color: #ffffff;
      &::placeholder {
        color: #ffffff;
      }
    }
    .ant-picker-separator, .ant-picker-suffix {
      color: #ffffff;
    }
    .ant-picker-clear:hover {
      border-radius: 9999px;
    }
  }
`;
