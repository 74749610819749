import React from "react";
import { Form, Input } from "antd";
import { FormItemProps } from "antd/lib/form";
import { InputProps } from "antd/lib/input";
import { FieldInputProps, FormikTouched, FormikErrors } from "formik";

import styled from "styled-components";

const InputStyle = styled.div`
  .form-control {
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input:hover,
    .ant-input-affix-wrapper:not(.ant-input-disabled):not(
        .ant-input-borderless
      ),
    .ant-input-affix-wrapper:not(.ant-input-disabled):not(
        .ant-input-borderless
      ):hover {
      background-color: #272727;
      border: 1px solid #342c24;
      background: #272727;
      color: #ffffff;
      input {
        background-color: #272727;
        background: #272727;
        color: #ffffff;
      }
    }
    .wrap-form {
      margin-top: 20px;
    }
    .ant-input {
      background-color: #272727;
      border: 1px solid #342c24;
      background: #272727;
      color: #ffffff;
    }
    .ant-form-item-has-error .ant-input {
      background: #272727;
    }
    .ant-input:hover {
      border-color: #40a9ff;
    }
    .ant-input:focus {
      border-color: #342c24;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
`;

export type InputFieldProps = {
  label?: string;
  field: FieldInputProps<any>;
  inputProps?: InputProps;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  disabled?: boolean;
  callbackOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (field: any, value: any) => any;
  pattern?: any;
  children?: React.ReactNode;
  ref?: any;
  suffix?: any;
} & Omit<FormItemProps, "children">;

const InputField: React.FC<InputFieldProps> = ({
  label,
  touched,
  error,
  field,
  inputProps,
  disabled,
  pattern,
  ref,
  setFieldValue,
  children,
  callbackOnChange,
  suffix,
  ...rest
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (pattern) {
      if (e.target.value === "" || pattern.test(e.target.value)) {
        setFieldValue(field.name, e.target.value);
      }
    } else {
      setFieldValue(field.name, e.target.value);
    }

    callbackOnChange?.(e);
  };

  return (
    <InputStyle>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={error && touched ? "error" : ""}
        help={(touched && error) as string}
        {...rest}
      >
        <Input
          {...field}
          {...inputProps}
          style={{ fontSize: "inherit" }}
          onChange={onChange}
          disabled={disabled ?? false}
          ref={ref}
          suffix={suffix}
        />
        {children}
      </Form.Item>
    </InputStyle>
  );
};

export default InputField;
