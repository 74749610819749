import React, { useState } from "react";
import { Col, Form, Layout, Row } from "antd";
import { Button } from "src/components";
import { Image } from "src/components";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";

import { LARGE_LOGO, DARK_BACKGROUND } from "src/assets/images";
// import { useAppDispatch } from "src/stores";
import {
  InputField,
  DatepickerField,
  CheckboxField
} from "src/components/form";

const { Content } = Layout;

const RegisterScreen: React.FC = () => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] =
    useState<boolean>(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email().required("EMAIL REQUIRED"),
    password: yup.string().required("PASSWORD REQUIRED").min(8),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("CONFIRM PASSWORD REQUIRED"),
    name: yup.string().required("NAME REQUIRED"),
    phone: yup.string().required("PHONE REQUIRED"),
    birthday: yup.string().required("BIRTHDAY REQUIRED"),
    lunaBirthday: yup.string().required("LUNA BIRTHDAY REQUIRED")
  });

  const initialValues = {
    email: "ittroller8@gmail.com",
    password: "123456789",
    confirmPassword: "123456789",
    name: "Nguyễn Văn A",
    phone: "0123123123",
    birthday: "2024-04-05",
    lunaBirthday: "Canh Thìn"
  };

  const onRegisterSuccess = (): void => {
    navigate("/verify_email");
  };

  const handleRegister = (value): void => {
    console.log(value);
    onRegisterSuccess();
    // void dispatch(registerAction({ data: value, callback: onRegisterSuccess }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleRegister(value);
    }
  });

  const { setFieldValue } = formik;

  return (
    <RegisterScreenStyle>
      <Row gutter={16} style={{ height: "100%" }}>
        <Col
          xs={24}
          sm={13}
          className="box-logo"
          style={{
            backgroundImage: `url(${DARK_BACKGROUND})`
          }}
        >
          <div className="logo">
            <Image src={LARGE_LOGO} />
          </div>
          <div className="title">
            <p className="brand-name">Phong Thủy Nhật Minh</p>
            <p className="description">
              Đặt lịch tư vấn, xem quẻ, luận giải về phong thủy
            </p>
          </div>
        </Col>
        <Col xs={24} sm={11} className="box-form">
          <Form
            name="login"
            className="login-form"
            onFinish={() => formik.handleSubmit()}
          >
            <p className="title">Đăng ký</p>
            <p className="sub-title">
              Bạn đã có tài khoản?
              <Link to={"/login"}> Đăng nhập ngay</Link>
            </p>
            <InputField
              label="Họ và tên"
              field={formik.getFieldProps("name")}
              setFieldValue={setFieldValue}
              className="form-control form-name"
              inputProps={{
                size: "middle",
                placeholder: "Nhập họ và tên"
              }}
              error={formik.errors.name}
              touched={formik.touched.name}
              required
            />
            <InputField
              label="Email"
              field={formik.getFieldProps("email")}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: "middle",
                placeholder: "Nhập Email"
              }}
              error={formik.errors.email}
              touched={formik.touched.email}
              required
            />

            <InputField
              label="Số điện thoại"
              field={formik.getFieldProps("phone")}
              setFieldValue={setFieldValue}
              className="form-control form-phone"
              inputProps={{
                size: "middle",
                placeholder: "Nhập số điện thoại"
              }}
              error={formik.errors.phone}
              touched={formik.touched.phone}
              required
            />

            <DatepickerField
              label="Ngày Sinh dương lịch"
              field={formik.getFieldProps("birthday")}
              setFieldValue={setFieldValue}
              className="form-control form-birthday"
              error={formik.errors.birthday}
              touched={formik.touched.birthday}
            />

            <InputField
              label="Ngày sinh âm lịch"
              field={formik.getFieldProps("lunaBirthday")}
              setFieldValue={setFieldValue}
              className="form-control form-luna-birthday"
              inputProps={{
                size: "middle",
                placeholder: "Nhập ngày sinh âm lịch"
              }}
              error={formik.errors.lunaBirthday}
              touched={formik.touched.lunaBirthday}
              suffix={<CalendarOutlined />}
              required
            />

            <InputField
              label="Mật khẩu"
              field={formik.getFieldProps("password")}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: "middle",
                placeholder: "Nhập mật khẩu",
                type: isShowPassword ? "text" : "password"
              }}
              error={formik.errors.password}
              touched={formik.touched.password}
              suffix={
                <div
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  style={{ cursor: "pointer" }}
                >
                  {isShowPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              }
              required
            />

            <InputField
              label="Nhập lại mật khẩu"
              field={formik.getFieldProps("confirmPassword")}
              setFieldValue={setFieldValue}
              className="form-control form-password-confirmation"
              inputProps={{
                size: "middle",
                placeholder: "Nhập lại mật khẩu",
                type: isShowConfirmPassword ? "text" : "password"
              }}
              error={formik.errors.confirmPassword}
              touched={formik.touched.confirmPassword}
              suffix={
                <div
                  onClick={() =>
                    setIsShowConfirmPassword(!isShowConfirmPassword)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {isShowConfirmPassword ? (
                    <EyeOutlined />
                  ) : (
                    <EyeInvisibleOutlined />
                  )}
                </div>
              }
              required
            />

            <div className="terms-conditions">
              <CheckboxField
                id="agree"
                field={formik.getFieldProps("agree")}
                setFieldValue={setFieldValue}
                className="form-control form-agree"
                // error={formik.errors.agree}
                // touched={formik.touched.agree}
              />
              <label htmlFor="agree">
                Tôi đồng ý với chính sách & điều khoản
              </label>
            </div>

            <Button className="btn-submit" htmlType="submit">
              Đăng ký
            </Button>
          </Form>
        </Col>
      </Row>
    </RegisterScreenStyle>
  );
};

export default RegisterScreen;

const RegisterScreenStyle = styled(Content)`
  @import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-row {
    height: 100%;
    .ant-form-item {
      margin-bottom: 20px;
      .ant-form-item-row {
        flex-direction: column;
      }
      .ant-form-item-label > label {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        color: #ead38e;
      }
    }
    .terms-conditions {
      display: flex;
      flex-direction: row;
      justify-content: start;
      gap: 10px;
      margin-bottom: 20px;
      color: #ffffff;
      label,
      .form-agree {
        margin: auto 0;
      }
    }
  }

  .box-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: "100%";
    background-size: "cover";
    background-position: "center";
    background-repeat: "repeat";
    padding: 50px;
    color: #ead38e;
    text-align: center;
    font-family: "Patrick Hand";
    .brand-name {
      font-size: 40px;
      line-height: 0;
    }
    .description {
      font-size: 28px;
    }
    .ant-image-img {
      height: 30%;
      width: 30%;
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 8% !important;
    background-color: #242424;
    .title {
      font-size: 32px;
      color: #ead38e;
      font-weight: bold;
    }
    .sub-title {
      font-size: 17px;
      color: #ffffff;
      a {
        color: red !important;
      }
    }
  }

  .login-form {
    width: 100%;
    input.ant-input {
      border: none;
    }
  }

  .btn-submit {
    width: 100%;
  }
`;
