import React from "react";
import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { FieldInputProps, FormikTouched, FormikErrors } from "formik";
import styled from "styled-components";

const SelectStyle = styled.div`
  .ant-select {
    .ant-select-selector {
      background-color: #272727;
      border: 1px solid #8e886f;
      .ant-select-selection-item,
      input::placeholder {
        color: #ffffff;
      }
      .ant-select-selection-placeholder {
        color: #8e886f;
      }
    }
    .ant-select-arrow {
      color: #ffffff;
    }
  }
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    background-color: #272727 !important;
    border-color: #8e886f !important;
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      background-color: #ead38e;
      border: none;
      color: black !important;
      .ant-select-selection-item-remove {
        color: black !important;
      }
    }
  }
`;

type Props = {
  label?: string;
  field: FieldInputProps<any>;
  optionsSelect: Array<{
    label: string | number | React.ReactElement;
    value: number | string;
    disabled?: boolean;
  }>;
  setFieldValue: (field: string, value: any) => void;
  selectProps?: SelectProps<any>;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  notFoundContent?: React.ReactNode;
  disabled?: boolean;
  placeholder?: string;
  mode?: "multiple" | "tags";
  style?: any;
  callbackOnSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  callbackOnDeselect?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
} & Omit<FormItemProps, "children">;

const SelectField: React.FC<Props> = ({
  label,
  touched,
  error,
  field,
  selectProps,
  optionsSelect,
  setFieldValue,
  notFoundContent,
  disabled,
  placeholder,
  mode,
  style,
  callbackOnSelect,
  callbackOnDeselect,
  ...rest
}) => {
  const onChangeField = (e: any): void => {
    setFieldValue(field.name, e);
  };

  const onSelectField = (e: any): void => {
    callbackOnSelect?.(e);
  }
  const onDeselectField = (e: any): void => {
    callbackOnDeselect?.(e);
  }

  return (
    <SelectStyle>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={error && touched ? "error" : ""}
        help={(touched && error) as string}
        {...rest}
      >
        <Select
          {...field}
          {...selectProps}
          options={optionsSelect}
          onChange={onChangeField}
          onSelect={onSelectField}
          onDeselect={onDeselectField}
          notFoundContent={notFoundContent}
          disabled={disabled}
          placeholder={placeholder}
          mode={mode}
          style={style}
        />
      </Form.Item>
    </SelectStyle>
  );
};

export default SelectField;
