import { createAsyncThunk } from '@reduxjs/toolkit';

import SETTING_API from './setting.api';

export const getListSetting = createAsyncThunk<any, any>(
  'setting',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await SETTING_API.listSettingAPI(data);
      return res;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const createSetting = createAsyncThunk<any, any>(
  'create_setting',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const result = await SETTING_API.createSettingAPI(data);
      console.log('33333333',result);
      if (result) {
        callback();
      }
      return result;
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const deleteSetting = createAsyncThunk<any, any>(
  'delete_setting',
  async ({ data }, { rejectWithValue }) => {
    try {
      return SETTING_API.deleteSettingAPI(data);
    } catch (err: any) {
      rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

