import React from "react";
import type { RouteObject } from "react-router-dom";

import { PublicLayout } from "src/layouts";
import { NotFound } from "src/screens/NotFound";
import { LoginScreen } from "src/screens/publicScreens";

const _publicRoutes: RouteObject[] = [
  {
    element: <PublicLayout />,
    children: [
      // { element: <HomeScreen /> },
      // { path: "about", element: <AboutScreen /> },
      {
        element: <LoginScreen />,
        path: "login"
      },
      { element: <NotFound />, path: "*" }
    ]
  }
];

export default _publicRoutes;
