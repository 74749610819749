import React, { useRef } from "react";
import { Form, message } from "antd";
import { FormItemProps } from "antd/lib/form";
import { FieldInputProps, FormikErrors, FormikTouched } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

const QuillEditorStyle = styled.div`
  width: 100%;
  height: 530px;
  .chat-box {
    margin-bottom: 0px;
  }
  .quill-editor-wrapper {
    .ql-snow .ql-editor img {
      max-width: 300px !important;
    }
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    .ql-snow .ql-stroke {
      stroke: #CDC29A;
    }
    .ql-snow .ql-picker {
      color: #CDC29A;
    }
    .ql-snow .ql-fill {
      fill: #CDC29A;
    }
    .ql-toolbar.ql-snow {
      border: 1px solid #544d39;
    }
    .ql-container.ql-snow {
      height: 430px;
      overflow: auto;
      border: 1px solid #544D39;
      &::-webkit-scrollbar {
        width: 3px;
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #745811;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #715e2d;
      }
    }
    .custom-editor {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding-top: 10px;
      .ql-snow .ql-editor img {
        max-width: 300px !important;
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #745811;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #715e2d;
      }
    }
  }

  .quill-editor-wrapper .ql-container {
    width: 100%;
    border: none;
    .ql-editor {
      &::-webkit-scrollbar {
        width: 5px;
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #745811;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #715e2d;
      }
    }
    .ql-editor,
    .ql-editor.ql-blank::before {
      color: #ffffff;
    }
  }

  .quill-upload-button {
    margin-left: 10px;
  }
  .btn-group {
    display: flex;
    position: absolute;
    right: 12px;
    bottom: 15px;
    .custom-btn {
      background: none;
      border: none;
    }
  }
`;

type Props = {
  label?: string;
  field: FieldInputProps<any>;
  setFieldValue: (field: any, value: any) => any;
  placeholder?: string;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  disable?: boolean;
} & Omit<FormItemProps, "children">;

const QuillNewsEditor: React.FC<Props> = ({
  label,
  touched,
  error,
  field,
  setFieldValue,
  placeholder,
  disable,
  ...rest
}) => {
  const quillRef = useRef<ReactQuill | null>(null);

  const handleChange = (value: string): void => {
    const quill = quillRef?.current?.getEditor();
    quill?.root.querySelectorAll('img').forEach(img => {
      const fileSize = img.src.length;
      if (fileSize > 2 * 1024 * 1024) {
        message.error('Hình ảnh phải nhỏ hơn 2MB!');
        img.remove();
      }
    });
    setFieldValue(field.name, value);
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "color",
    "background",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <QuillEditorStyle>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={error && touched ? "error" : ""}
        help={(touched && error) as string}
        {...rest}
      >
        <div style={{ color: "#EAD38E", fontSize: "18px" }}>Nội dung</div>
        <div className="quill-editor-wrapper">
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={field.value}
            onChange={handleChange}
            placeholder={placeholder}
            readOnly={disable ?? false}
            modules={modules}
            formats={formats}
            className="custom-editor"
          />
        </div>
      </Form.Item>
    </QuillEditorStyle>
  );
};

export default QuillNewsEditor;
