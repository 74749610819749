import React from "react";
import { DatePickerStyled } from "./datePickerStyle";

interface datePickerComponentProps {
  className?: string;
  style?: any;
  format?: string;
  placeholder?: string;
  onChange?: (value: any | null, dateString: string) => void;
}

const DatePickerComponent: React.FC<datePickerComponentProps> = ({
  className,
  style,
  format,
  placeholder,
  onChange,
  ...props
}) => {
  return (
    <DatePickerStyled className={className} format={format} placeholder={placeholder} {...props} style={style} onChange={onChange} />
  );
};

export default DatePickerComponent;
