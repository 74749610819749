import { createSlice } from '@reduxjs/toolkit';
import {
  getListUsers,
  getDetailUser,
  createUser,
  updateUser,
  deleteUser,
  addMissingAttribute,
  deleteMissingAttribute
} from './user.action';

const initialState: User.UserState = {
  isLoading: false,
  error: null,
  listUsers: [],
  detailUser: null
};

const { actions, reducer } = createSlice({
  name: 'user_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListUsers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listUsers = action.payload;
      })
      .addCase(getListUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getDetailUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDetailUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detailUser = action.payload;
      })
      .addCase(getDetailUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(createUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(updateUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(deleteUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(addMissingAttribute.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addMissingAttribute.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addMissingAttribute.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(deleteMissingAttribute.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteMissingAttribute.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteMissingAttribute.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
  },
});

export { reducer };
export default actions;
