import styled from 'styled-components';

const UserManagementStyle = styled.div`
  .header-content {
    display: flex;
    justify-content: space-between;
    .title {
      color: #EAD38E;
    }
  }
  .content {
    border: 1px solid #745811;
    border-radius: 5px;
    padding: 10px;
    .filter-box {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .search-box {
        display: flex;
        .ant-input-affix-wrapper {
          .ant-input-prefix {
            color: #342C24;
          }
          border: 1px solid #342C24;
          background-color: #272727;
          .ant-input {
            background-color: #272727;
            color: #ffffff;
          }
        }
      }
    }
    .empty-content {
      margin-top: 30px;
      height: calc(100vh - 300px);
    }
    .data-table {
      margin-top: 30px;
      height: calc(100vh - 300px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .ant-table-wrapper {
        overflow: auto;
        &::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #745811;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: #715e2d;
        }
        &::-webkit-scrollbar-corner {
          background-color: transparent;
        }
        .ant-table {
          .ant-table-thead {
            th {
              background-color: #322B1C;
              color: #ffffff;
              border: none;
            }
          }
          .ant-table-tbody {
            td {
              background-color: #222222;
              border: none;
              color: #ffffff;
              .name-box {
                display: flex;
                gap: 10px;
                .ant-image-img {
                  border-radius: 9999px !important;
                }
                p {
                  margin: auto 0;
                }
              }
            }
            .action-box {
              display: flex;
              gap: 5px;
              .ant-btn {
                color: #ffffff;
                background-color: transparent;
                border: none;
              }
            }
            .ant-empty-description {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

`;

export default UserManagementStyle;