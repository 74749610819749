import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const APPOINTMENT_API = {
  listAppointmentRequestsAPI: async (params: any) => {
    const response = await new AxiosClient().get(`/appointment/requests?${new URLSearchParams(params).toString()}`);
    const data = get(response, 'data', null);
    return data;
  },
  updateAppointmentRequestsAPI: async (params: any) => {
    const response = await new AxiosClient().put(`/appointment/requests/${params.id}`, params);
    const data = get(response, 'data', null);
    return data;
  },
  deleteAppointmentRequestsAPI: async (params: any) => {
    const response = await new AxiosClient().delete(`/appointment/requests/${params.id}`);
    const data = get(response, 'data', null);
    return data;
  },
  approveAppointmentRequestsAPI: async (params: any) => {
    const response = await new AxiosClient().post('/appointment/approve', params);
    const data = get(response, 'data', null);
    return data;
  },
  rejectAppointmentRequestsAPI: async (params: any) => {
    const response = await new AxiosClient().post('/appointment/reject', params);
    const data = get(response, 'data', null);
    return data;
  },
  listAppointmentRequestsFilterAPI: async (params: any) => {
    const response = await new AxiosClient().get(`/appointment/request/filter?${new URLSearchParams(params).toString()}`);
    // const data = get(response, 'data', null);
    return response;
  },
};

export default APPOINTMENT_API;
