import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const NEW_API = {
  listNewsAPI: async (params: any) => {
    const response = await new AxiosClient().get(`/news?${new URLSearchParams(params).toString()}`);
    return get(response, 'data', null);
  },
  createNewsAPI: async (params) => {
    const response = await new AxiosClient().post('/news', params);
    const data = get(response, 'data', null);
    return data;
  },
  updateNewsAPI: async (params) => {
    const response = await new AxiosClient().put('/news/' + params.id, params);
    const data = get(response, 'data', null);
    return data;
  },
  detailNewsAPI: async (params) => {
    const response = await new AxiosClient().get('/news/' + params.id, params);
    const data = get(response, 'data', null);
    return data;
  },
  deleteNewsAPI: async (id) => {
    const response = await new AxiosClient().delete('/news/' + id);
    const data = get(response, 'data', null);
    return data;
  },
};

export default NEW_API;
