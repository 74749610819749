import styled from 'styled-components';
import { Modal } from "src/components";

const InfoModalStyle = styled(Modal)`
  &.modal-info {
    .avatar img.ant-image-img {
      height: 150px;
      width: 150px;
      border-radius: 9999px;
    }
    .info-item {
      display: flex;
      .label {
        min-width: 140px;
        color: #EAD38E;
      }
      .value {
        color: #ffffff;
      }
      .link {
        color: #EAD38E;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .col-avatar {
      display: none;
    }
    .col-info {
      min-width: 100%;
    }
  }
`;

export default InfoModalStyle;
