import { Spin } from 'antd';
import styled from 'styled-components';

export const SpinStyle = styled(Spin)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  &.ant-spin-nested-loading {
    background-color: transparent !important;
    height: 100%;
    width: 100%;
  }
`;
