export const DATE_FORMAT = "DD/MM/YYYY";
export const WORK_RANGE = [
  { label: "Tất cả", value: "" },
  { label: '08:00 - 10:00', value: "frame_01" },
  { label: '12:00 - 14:00', value: "frame_02" },
  { label: "14:00 - 16:00", value: "frame_03" },
  { label: "16:00 - 18:00", value: "frame_04" },
  { label: "19:30 - 21:30", value: "frame_05" },
]
export const TIME_LINE = [
  { key: "frame_01", value: "08:00 - 10:00" },
  { key: "frame_02", value: "10:00 - 12:00" },
  { key: "frame_03", value: "14:00 - 16:00" },
  { key: "frame_04", value: "16:00 - 18:00" },
  { key: "frame_05", value: "19:30 - 21:30" },
];

export const PERIOD = [
  { label: "Hôm nay", value: "day" },
  { label: "Theo tuần", value: "week" },
  { label: "Theo tháng", value: "month" },
  { label: "Tất cả", value: "all" }
]
