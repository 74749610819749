import { Card } from 'antd';
import styled from 'styled-components';

export const CardStyle = styled(Card)`
  && {
    border: 1px solid rgba(116, 88, 18, 1);
    border-radius: 8px;
    width: 100%;
    background: rgba(34, 32, 30, 1);
    height: 100%;

    .ant-card-head {
      background: radial-gradient(66.3% 39.31% at 50% 5%, rgba(213, 153, 7, 0.49) 0%, rgba(0, 0, 0, 0.2) 100%), #12100f;
      box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25), inset 0px 5px 6px 1px #000000;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .ant-card-head-title {
        font-weight: 500;
        font-size: 18px;
        color: #efd485;
        text-align: center;
      }
    }
    .ant-card-body {
      flex-direction: column;
      padding: 24px;
      border-radius: 8px;
      background: rgba(34, 32, 30, 1);
    }
    &&:has(.ant-card-head) .ant-card-body {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .content {
      padding-top: 18px;
    }
  }
`;
