import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalComponentStyle = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      color: #ffffff;
    }
    background-color: #222222;
    border: 1px solid #745811;
    .ant-modal-header {
      background-color: #222222;
      border: none;
      .ant-modal-title {
        color: #ffffff;
      }
    }
    .ant-modal-body {
      .ant-form-item-label {
        label {
          color: #EAD38E;
        }
      }
      .ant-form-item-control-input-content {
        input {
          color: #ffffff;
          background-color: #272727;
          border: 1px solid #8E886F;
          &::placeholder {
            color: #8E886F;
          }
        }
        .ant-picker {
          background-color: #272727;
          border: 1px solid #8E886F;
          input {
            border: none;
          }
          .anticon {
            color: #8C8C8C;
          }
        }
      }
      .button-footer {
        display: flex;
        justify-content: end;
        gap: 5px;
        margin-top: 20px;
      }
    }
  }
`;
