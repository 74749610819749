import { createSlice } from '@reduxjs/toolkit';
import { getListNews, createNew, updateNew, deleteNew, detailNew } from './new.action';

const initialState: any = {
  isLoading: false,
  error: null,
  listNews: [],
  detailNewData: {}
};

const { actions, reducer } = createSlice({
  name: 'new_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListNews.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listNews = action.payload;
      })
      .addCase(getListNews.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(createNew.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createNew.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createNew.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(updateNew.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateNew.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateNew.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(deleteNew.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteNew.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteNew.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(detailNew.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(detailNew.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detailNewData = action.payload;
      })
      .addCase(detailNew.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
  },
});

export { reducer };
export default actions;
