import styled from 'styled-components';

export const TimeLineStyleStyle = styled.div`
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ead38e;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .wrapper-content {
    padding: 12px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 208, 0, 0.22) 100%);
    border-left: 2px solid #af9026;
    margin: 12px 0;

    .date-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.41px;
        text-align: left;
        color: #ead38e;
      }
      .label-day {
        width: fit-content;
        padding: 0 4px;
        border-radius: 4px;
        background: #ead38e;
        color: #342c24;
        font-size: 12px;
      }
    }
    .decription {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-align: left;
      color: #ffffff;
    }
  }
`;

export const WrapperNoteStyle = styled.div`
  /* display: flex;
  gap: 20; */
`;

