import { createAsyncThunk } from '@reduxjs/toolkit';

import APPOINTMENT_API from './appointment-request.api';

export const listAppointmentRequests = createAsyncThunk<any, AppointmentRequest.AppointmentRequestPayload>(
  'appointmment_request',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.listAppointmentRequestsAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateAppointmentRequests = createAsyncThunk<any, AppointmentRequest.AppointmentRequestPayload>(
  'update_appointmment_request',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.updateAppointmentRequestsAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteAppointmentRequests = createAsyncThunk<any, AppointmentRequest.AppointmentRequestPayload>(
  'delete_appointmment_request',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.deleteAppointmentRequestsAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const approveAppointmentRequests = createAsyncThunk<any, any>(
  'approve_appointmment_request',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.approveAppointmentRequestsAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const rejectAppointmentRequests = createAsyncThunk<any, any>(
  'reject_appointmment_request',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.rejectAppointmentRequestsAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const listAppointmentRequestsFilter = createAsyncThunk<any, AppointmentRequest.AppointmentRequestPayload>(
  'appointmment_request_filter',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.listAppointmentRequestsFilterAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
