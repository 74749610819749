import React from "react";
import { ModalComponentStyle } from "./modalStyle";

interface timeComponentProps {
  children?: any;
  width?: number;
  title?: string;
  visible: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  className?: string;
  okText?: string;
  cancelText?: string;
  maskClosable?: boolean;
}

const ModalComponent: React.FC<timeComponentProps> = ({
  children,
  width,
  title,
  visible,
  onOk,
  onCancel,
  className,
  okText,
  cancelText,
  maskClosable,
  ...props
}) => {
  return (
    <ModalComponentStyle
      centered
      width={width}
      title={title}
      visible={visible}
      className={className}
      onOk={onOk}
      okText={okText}
      cancelText={cancelText}
      onCancel={onCancel}
      maskClosable={maskClosable}
      footer={null}
      {...props}
    >
      {children}
    </ModalComponentStyle>
  );
};

export default ModalComponent;
