import React, { useEffect, useState } from "react";
import { AppointmentStyle } from "./SettingAppointmentStyle";
import { CardStyle } from "src/utils/styled";
import moment from "moment-timezone";
import "moment/locale/vi";
import { Table, TableProps, message } from "antd";
import { Button, Pagination, Empty } from "src/components";
import { DeleteOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "src/stores";
import {
  getListSetting,
  deleteSetting
} from "src/stores/screens/setting/setting.action";
import DeleteModal from "./delete-modal/DeleteModal";
import FormModal from "./form-modal/FormModal";
import { TIME_LINE } from "src/constants";
import { SpinStyle } from "src/components/style";

const AppointmentScreen: React.FC = () => {
  const initUser = {
    username: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: 0
  };
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<User.UserType>(initUser);
  moment.locale("vi");
  const { listSettings, isLoading } = useAppSelector((state) => state.setting);

  useEffect(() => {
    fetchSetting();
  }, []);

  const fetchSetting = (page = 1) => {
    void dispatch(getListSetting({ data: { page: page } }));
    setCurrentPage(page);
  };

  const getValueByKey = (key) => {
    const item = TIME_LINE.find((item) => item.key === key);
    return item ? item.value : null;
  };

  const columns: TableProps<any>["columns"] = [
    {
      title: "Ngày bắt đầu",
      dataIndex: "busyDate",
      key: "busyDate",
      width: 130,
      onCell: () => ({ style: { minWidth: 130 } }),
      render: (_, record: any) => {
        return (
          <span style={{ color: "#ead38e" }}>
            {record.busyDate
              ? ` ${moment(record?.busyDate, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )}`
              : "-"}
          </span>
        );
      }
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "endDate",
      key: "endDate",
      width: 130,
      onCell: () => ({ style: { minWidth: 130 } }),
      render: (_, record: any) => {
        return (
          <span style={{ color: "#ead38e" }}>
            {record.endDate
              ? ` ${moment(record?.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}`
              : "-"}
          </span>
        );
      }
    },
    {
      title: "Thời gian",
      dataIndex: "busyTimeFrame",
      key: "busyTimeFrame",
      width: 120,
      onCell: () => ({ style: { minWidth: 120 } }),
      render: (busyTimeFrame) => {
        return <div>{getValueByKey(busyTimeFrame)}</div>;
      }
    },
    {
      title: "Action",
      key: "action",
      width: 70,
      onCell: () => ({ style: { minWidth: 70 } }),
      render: (_, record) => (
        <div className="action-box">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setUser(record);
              setIsModalDeleteOpen(true);
            }}
          >
            <DeleteOutlined />
          </Button>
        </div>
      )
    }
  ];

  const handleRowClick = (user: User.UserType) => {
    setUser(user);
  };

  const onChangePage = (value: number) => {
    fetchSetting(value);
  };

  const handleDeleteOk = async () => {
    const result = await dispatch(deleteSetting({ data: user.id }));
    setIsModalDeleteOpen(false);
    if (result) {
      message.success("Xóa lịch bận thành công");
      fetchSetting();
    } else {
      message.success("Xóa lịch bận thất bại");
    }
  };

  const onCreateBusyTime = () => {
    setUser(initUser);
    setIsModalFormOpen(true);
  };

  const handleOkFormUser = () => {
    fetchSetting();
    setIsModalFormOpen(false);
  };

  return (
    <AppointmentStyle className="container">
      <SpinStyle
        spinning={isLoading}
        wrapperClassName="root-spin"
        className="root-spin-component"
      >
        <div className="header-content">
          <h2 className="title">Cài đặt lịch hẹn</h2>
          {!!listSettings?.data?.length && (
            <Button className="create-user" onClick={() => onCreateBusyTime()}>
              Thêm lịch bận
            </Button>
          )}
        </div>
        <CardStyle title="Lịch bận">
          {listSettings?.data?.length ? (
            <div className="data-table">
              <Table
                dataSource={listSettings?.data || []}
                columns={columns}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: () => handleRowClick(record)
                  };
                }}
              />
              <Pagination
                total={listSettings?.total}
                onChange={onChangePage}
                current={currentPage}
                pageSize={10}
              />
            </div>
          ) : (
            <Empty
              className="empty-content"
              isShowCreate
              contentText="Chưa có lịch bận nào trong danh sách"
              buttonText="Thêm lịch bận"
              onCreate={() => onCreateBusyTime()}
            />
          )}
        </CardStyle>
        <FormModal
          isModalFormOpen={isModalFormOpen}
          user={user}
          handleCancel={() => setIsModalFormOpen(false)}
          handleOk={handleOkFormUser}
        />
        <DeleteModal
          isModalDeleteOpen={isModalDeleteOpen}
          handleCancel={() => setIsModalDeleteOpen(false)}
          handleOk={() => handleDeleteOk()}
        />
      </SpinStyle>
    </AppointmentStyle>
  );
};

export default AppointmentScreen;
