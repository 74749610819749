import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from 'src/stores/app';

import { reducer as authReducer } from './screens/auth/auth.reducer';
import { reducer as userReducer } from './screens/user/user.reducer';
import { reducer as appointmentReducer } from './screens/appointment/appointment.reducer';
import { reducer as newReducer } from './screens/new/new.reducer';
import { reducer as appointmentRequestReducer } from './screens/appointment-request/appointment-request.reducer';
import { reducer as settingReducer } from './screens/setting/setting.reducer';
import { reducer as consultationReducer } from './screens/consultation/consultation.reducer';

export const reducer = combineReducers({
  app: appReducer,

  auth: authReducer,
  user: userReducer,
  appointment: appointmentReducer,
  new: newReducer,
  appointmentRequest: appointmentRequestReducer,
  setting: settingReducer,
  consultation: consultationReducer,
});
