import { createAsyncThunk } from '@reduxjs/toolkit';

import APPOINTMENT_API from './appointment.api';

export const getListAppointments = createAsyncThunk<any, any>(
  'list_appointments',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.listAppointmentsAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateAppointmentStatus = createAsyncThunk<any, any>(
  'update_appointment_status',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.updateAppointmentStatusAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getListAppointmentsTypeDate = createAsyncThunk<any, any>(
  'list_appointments_type_date',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.listAppointmentsTypeDateAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getListAppointmentsTypeYear = createAsyncThunk<any, any>(
  'list_appointments_type_year',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.listAppointmentsTypeYearAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getListAppointmentsByNewMessage = createAsyncThunk<any, any>(
  'list_appointments_by_new_message',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await APPOINTMENT_API.listAppointmentsByNewMessageAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
