import styled from 'styled-components';
import { Modal } from "src/components";

const StatusModalStyle = styled(Modal)`
  &.modal-status {
    .text-content {
      margin-bottom: 30px;
      color: #ffffff;
    }
    .field-status {
      display: flex;
      color: #ffffff;
      margin-bottom: 40px;
      gap: 30px;
      p, .status {
        margin: auto 0;
      }
    }
  }
`;

export default StatusModalStyle;
