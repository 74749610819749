import React from "react";
import DeleteModalStyle from "./DeleteModalStyle";
import { Button } from "src/components";

interface DeleteModalProps {
  isModalDeleteOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isModalDeleteOpen,
  handleOk,
  handleCancel
}) => {
  return (
    <DeleteModalStyle
      title="Xóa tin tức"
      visible={isModalDeleteOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="modal-delete"
    >
      <p className="text-content">Bạn chắc chắn xóa tin tức này?</p>
      <div className="button-footer">
        <Button buttonType="cancel" onClick={handleCancel} width={100}>
          Đóng
        </Button>
        <Button htmlType="submit" onClick={handleOk} width={100}>
          Đồng ý
        </Button>
      </div>
    </DeleteModalStyle>
  );
};

export default DeleteModal;
