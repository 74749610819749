import { createSlice } from '@reduxjs/toolkit';
import {
  getListAppointments,
  updateAppointmentStatus,
  getListAppointmentsTypeDate,
  getListAppointmentsTypeYear,
  getListAppointmentsByNewMessage
} from './appointment.action';

const initialState: any = {
  isLoading: false,
  error: null,
  listAppointments: [],
  listAppointmentsTypeDate: null,
  listAppointmentsTypeYear: null,
  listAppointmentsByNewMessage: null,
  user: null
};

const { actions, reducer } = createSlice({
  name: 'appointment_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListAppointments.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListAppointments.fulfilled, (state, action) => {
        state.isLoading = false;
        const newData = action.payload?.data?.map((item: any) => {
          if (!item.isRepeated) return item;
          const children = item?.appointments?.map(appointment => ({
            ...appointment,
            isChild: true
          }));

          return { ...item, children: children, key: item.id };

        });
        state.listAppointments = { total: action.payload?.total, data: newData };
      })
      .addCase(getListAppointments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(updateAppointmentStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateAppointmentStatus.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateAppointmentStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getListAppointmentsTypeDate.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListAppointmentsTypeDate.fulfilled, (state, action) => {
        state.isLoading = false;
        const newData = action.payload?.map((item: any) => {
          const children = item?.appointments?.map((appointment: any) => ({
            ...appointment,
            isChild: true
          }));

          return { ...item, children: children, key: item.requestDate, isParent: true };

        });
        state.listAppointmentsTypeDate = { data: newData };
      })
      .addCase(getListAppointmentsTypeDate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getListAppointmentsTypeYear.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListAppointmentsTypeYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listAppointmentsTypeYear = action.payload;
      })
      .addCase(getListAppointmentsTypeYear.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getListAppointmentsByNewMessage.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListAppointmentsByNewMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listAppointmentsByNewMessage = action.payload.data;
      })
      .addCase(getListAppointmentsByNewMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
  },
});

export { reducer };
export default actions;
