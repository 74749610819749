import React from 'react';
import { DatePicker, Form } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { DatePickerProps } from 'antd/lib/date-picker';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import moment, { Moment } from 'moment-timezone';
import styled from 'styled-components';

type Props = {
  label?: string;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  datepickerProps?: DatePickerProps;
  setFieldValue: (field: string, value: any) => any;
  field: FieldInputProps<any>;
  format?: string;
  placeholder?: string;
  disabled?: boolean;
  callBackOnChange?: (...args: any[]) => void;
} & Omit<FormItemProps, 'children'>;

const InputStyle = styled.div`
  .form-control {
    .ant-picker-status-error.ant-picker,
    .ant-picker-status-error.ant-picker:not([disabled]):hover {
      background-color: #272727;
    }
    .ant-picker {
      background-color: #272727;
      border: 1px solid #342c24;
      background: #272727;
      color: #ffffff;
    }
    .ant-picker {
      background-color: #272727;
      border: 1px solid #342c24;
      background: #272727;
      color: #ffffff;
    }
    .ant-picker-input {
      input,
      .ant-picker-suffix {
        color: #ffffff;
      }
    }
  }
`;

const DatepickerField: React.FC<Props> = ({
  label,
  touched,
  error,
  field,
  setFieldValue,
  datepickerProps,
  placeholder,
  format,
  disabled,
  callBackOnChange,
  ...rest
}) => {
  const onChange = (_: Moment | null, dateString: string): void => {
    setFieldValue(field.name, dateString);
    callBackOnChange?.();
  };

  const valueMoment = field.value ? moment(field.value) : undefined;

  return (
    <InputStyle>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={error && touched ? 'error' : ''}
        help={(touched && error) as string}
        {...rest}
      >
        <DatePicker
          {...field}
          {...datepickerProps}
          format={format}
          placeholder={placeholder}
          allowClear
          onChange={onChange}
          value={valueMoment}
          style={{ width: '100%' }}
          disabled={disabled}
        />
      </Form.Item>
    </InputStyle>
  );
};

export default DatepickerField;
