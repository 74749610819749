import { LunarDate } from 'vietnamese-lunar-calendar';

export const lunarDate = (date: string) => {
  const sonarDate = new Date(date)
  const lunarDateObj = new LunarDate(sonarDate)
  const lunarDate = `${lunarDateObj.lunarDate.can} ${lunarDateObj.lunarDate.chi}`
  const lunarMonth = `${lunarDateObj.lunarMonth.can} ${lunarDateObj.lunarMonth.chi}`
  const lunarYear = `${lunarDateObj.lunarYear.can} ${lunarDateObj.lunarYear.chi}`
  return `Ngày ${lunarDate}, Tháng ${lunarMonth}, Năm ${lunarYear}`
}

export const getLunarYearName = (year: number): string => {
  const thienCan: string[] = [
    "Giáp",
    "Ất",
    "Bính",
    "Đinh",
    "Mậu",
    "Kỷ",
    "Canh",
    "Tân",
    "Nhâm",
    "Quý"
  ];
  const diaChi: string[] = [
    "Tý",
    "Sửu",
    "Dần",
    "Mão",
    "Thìn",
    "Tỵ",
    "Ngọ",
    "Mùi",
    "Thân",
    "Dậu",
    "Tuất",
    "Hợi"
  ];

  const canIndex: number = (year + 6) % 10;
  const chiIndex: number = (year + 8) % 12;
  const can: string = thienCan[canIndex];
  const chi: string = diaChi[chiIndex];
  return `${can} ${chi}`;
};
