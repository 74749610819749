import styled from 'styled-components';
import { Modal } from "src/components";

const DeleteModalStyle = styled(Modal)`
  &.modal-delete {
    .text-content {
      margin-bottom: 30px;
      color: #ffffff;
    }
  }
`;

export default DeleteModalStyle;
