import React from "react";
import { Form, Input } from "antd";
import { FormItemProps } from "antd/lib/form";
import { TextAreaProps } from "antd/lib/input/TextArea";
import { FieldInputProps, FormikTouched, FormikErrors } from "formik";
import styled from "styled-components";

const { TextArea } = Input;

type Props = {
  label?: string | React.ReactElement;
  field: FieldInputProps<any>;
  textAreaProps?: TextAreaProps;
  placeholder?: string;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  setFieldValue: (field: any, value: any) => any;
  callbackOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pattern?: any;
  disabled?: boolean;
} & Omit<FormItemProps, "children">;

const TextAreaField: React.FC<Props> = ({
  label,
  touched,
  error,
  field,
  textAreaProps,
  placeholder,
  disabled,
  pattern,
  setFieldValue,
  callbackOnChange,
  ...rest
}) => {
  return (
    <CustomStyle>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={error && touched ? "error" : ""}
        help={(touched && error) as string}
        {...rest}
      >
        <TextArea
          {...field}
          {...textAreaProps}
          disabled={disabled}
          placeholder={placeholder}
          style={{ fontSize: "inherit" }}
        />
      </Form.Item>
    </CustomStyle>
  );
};

export default TextAreaField;

const CustomStyle = styled.div`
  .ant-input {
    background-color: #272727;
    border: 1px solid #342c24;
    background: #272727;
    color: #ffffff;
  }
  .ant-form-item-has-error .ant-input {
    background: #272727;
  }
  .ant-input:hover {
    border-color: #40a9ff;
  }
  .ant-input:focus {
    border-color: #342c24;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:hover {
      background-color: #272727;
  }
`;
