import React from "react";
import { Col, Form, Layout, Row } from "antd";
import { Button } from "src/components";
import { Image } from "src/components";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { LARGE_LOGO, DARK_BACKGROUND } from "src/assets/images";
import { InputField } from "src/components/form";

const { Content } = Layout;

const ChangePasswordScreen: React.FC = () => {
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    email: yup.string().email().required("EMAIL REQUIRED")
  });

  const initialValues = {
    email: "user@example.com"
  };

  const onSubmitSuccess = (): void => {
    // handle verify email
    navigate("/change_password");
  };

  const handleVerify = (value): void => {
    console.log(value);
    onSubmitSuccess();
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleVerify(value);
    }
  });

  const { setFieldValue } = formik;

  return (
    <ChangeScreenStyle>
      <Row gutter={16} style={{ height: "100%" }}>
        <Col
          xs={24}
          sm={13}
          className="box-logo"
          style={{
            backgroundImage: `url(${DARK_BACKGROUND})`
          }}
        >
          <div className="logo">
            <Image src={LARGE_LOGO} />
          </div>
          <div className="title">
            <p className="brand-name">Phong Thủy Nhật Minh</p>
            <p className="description">
              Đặt lịch tư vấn, xem quẻ, luận giải về phong thủy
            </p>
          </div>
        </Col>
        <Col xs={24} sm={11} className="box-form">
          <Form
            name="login"
            className="login-form"
            onFinish={() => formik.handleSubmit()}
          >
            <p className="title">Quên mật khẩu</p>
            <p className="sub-title">
              Để khôi phục mật khẩu, bạn vui lòng nhập email đã dùng để đăng ý
              trên hệ thống
            </p>
            <InputField
              label="Email"
              field={formik.getFieldProps("email")}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: "middle",
                placeholder: "Nhập email"
              }}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
            <Button className="btn-submit" htmlType="submit">
              Gửi yêu cầu
            </Button>
          </Form>
        </Col>
      </Row>
    </ChangeScreenStyle>
  );
};

export default ChangePasswordScreen;

const ChangeScreenStyle = styled(Content)`
  @import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-row {
    height: 100%;
    .ant-form-item {
      margin-bottom: 20px;
      .ant-form-item-row {
        flex-direction: column;
      }
      .ant-form-item-label > label {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        color: #ead38e;
      }
    }
  }

  .box-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: "100%";
    background-size: "cover";
    background-position: "center";
    background-repeat: "repeat";
    padding: 50px;
    color: #ead38e;
    text-align: center;
    font-family: "Patrick Hand";
    .brand-name {
      font-size: 40px;
      line-height: 0;
    }
    .description {
      font-size: 28px;
    }
    .ant-image-img {
      height: 30%;
      width: 30%;
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 8% !important;
    background-color: #242424;
    .title {
      font-size: 32px;
      color: #ead38e;
      font-weight: bold;
    }
    .sub-title {
      font-size: 17px;
      color: #ffffff;
    }
  }

  .login-form {
    width: 100%;
    input.ant-input {
      border: none;
    }
  }

  .btn-submit {
    width: 100%;
  }
`;
