import React, { useEffect, useState } from "react";
import ListRequirementsStyle from "./ListRequirementsStyle";
import { Button, Pagination, Empty } from "src/components";
import { Table } from "antd";
import { WORK_RANGE, REQUEST_STATUS, PERIOD } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/stores";
import { listAppointmentRequestsFilter } from "src/stores/screens/appointment-request/appointment-request.action";

import AcceptModal from "./accept_modal/AcceptModal";
import RejectModal from "./reject_modal/RejectModal";
import { formatDateDDMMYYYY } from "src/utils";
import { SpinStyle } from "src/components/style";
import classNames from "classnames";

const ListRequirementsScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { listRequestsFilter, isLoading } = useAppSelector(
    (state) => state.appointmentRequest
  );

  const columns: any = [
    {
      title: "Tên khách hàng",
      dataIndex: "user",
      key: "user",
      width: 180,
      onCell: () => ({ style: { minWidth: 180 } }),
      render: (attr: any) => {
        return (
          <div
            style={{
              color: "#EAD38E",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "148px",
              whiteSpace: "nowrap"
            }}
          >
            {attr?.username}
          </div>
        );
      }
    },
    {
      title: "Facebook",
      dataIndex: "user",
      key: "user",
      width: 180,
      onCell: () => ({ style: { minWidth: 180 } }),
      render: (_, i: any) => {
        return (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "148px",
              whiteSpace: "nowrap"
            }}
          >
            {i.isChild ? "" : i.user?.facebook || "-"}
          </div>
        );
      }
    },
    {
      title: "Trạng thái",
      dataIndex: "requestStatus",
      key: "requestStatus",
      align: "center",
      width: 120,
      onCell: () => ({ style: { minWidth: 120 } }),
      render: (status: string) => (
        <div style={{ color: statusRequest(status)?.color }}>
          {statusRequest(status)?.label}
        </div>
      )
    },
    {
      title: "Ngày đặt luận giải",
      dataIndex: "requestDate",
      key: "requestDate",
      align: "center",
      width: 150,
      onCell: () => ({ style: { minWidth: 150 } }),
      render: (date) => {
        return <div>{!!date ? formatDateDDMMYYYY(date) : ""}</div>;
      }
    },
    {
      title: "Thời gian",
      key: "requestTime",
      dataIndex: "requestTime",
      align: "center",
      width: 120,
      onCell: () => ({ style: { minWidth: 120 } }),
      render: (item: any) => (
        <div>
          {!!item ? WORK_RANGE.find((i) => i.value === item)?.label : ""}
        </div>
      )
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 200,
      onCell: () => ({ style: { minWidth: 200 } }),
      render: (_, record) => (
        <div className="action-box">
          {!record.isParent && (
            <>
              <Button
                onClick={() => {
                  onOpenAcceptModal(record);
                }}
                disabled={["approve", "reject"].includes(record.requestStatus)}
              >
                Đồng ý
              </Button>
              <Button
                onClick={() => onOpenRejectctModal(record)}
                buttonType="cancel"
                disabled={["approve", "reject"].includes(record.requestStatus)}
              >
                Từ chối
              </Button>
            </>
          )}
        </div>
      )
    }
  ];

  const [isModalAcceptOpen, setIsModalAcceptOpen] = useState(false);
  const [isModalRejectOpen, setIsModalRejectOpen] = useState(false);
  const [request, setRequest] = useState({});
  const [filterParams, setFilterParams] = useState({ filter: "all" });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = (params = filterParams, page = 1) => {
    const paramsQuery = cleanFilter({ ...params, page: page });
    void dispatch(listAppointmentRequestsFilter({ data: paramsQuery }));
    setCurrentPage(page);
    setFilterParams(params);
  };

  const cleanFilter = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === "") {
        delete obj[key];
      }
    });
    return obj;
  };

  const statusRequest = (status: string) => {
    return REQUEST_STATUS.find((i) => status === i.value);
  };

  const onOpenAcceptModal = (request: string) => {
    setRequest(request);
    setIsModalAcceptOpen(true);
  };

  const onOpenRejectctModal = (request: any) => {
    setRequest(request);
    setIsModalRejectOpen(true);
  };
  const handleAcceptOk = () => {
    fetchRequests();
    setIsModalAcceptOpen(false);
  };

  const handleRejectOk = () => {
    fetchRequests();
    setIsModalRejectOpen(false);
  };

  const onChangePage = (value: number) => {
    fetchRequests(filterParams, value);
  };

  // const onChangeDate = (_e: any, date: string) => {
  //   if (date) {
  //     const formattedDate = formatDateYYYYMMDD(date);
  //     fetchRequests({ ...filterParams, requestDate: formattedDate });
  //   } else {
  //     fetchRequests({ ...filterParams, requestDate: "" });
  //   }
  // };

  const onChangePeriod = (item: any) => {
    fetchRequests({ ...filterParams, filter: item.value });
  };

  const getIcon = (icon: string) => {
    let iconSrc: any;
    switch (icon) {
      case "day":
        iconSrc = (
          <>
            <svg width="13" height="13" viewBox="0 0 16 16" fill="none">
              <g>
                <path d="M6.79453 13.0607C6.90986 13.2173 7.08719 13.316 7.28119 13.3313C7.29853 13.3327 7.31586 13.3333 7.33253 13.3333C7.50853 13.3333 7.67853 13.264 7.80386 13.138L11.6372 9.30467C11.8979 9.044 11.8979 8.62267 11.6372 8.362C11.3765 8.10134 10.9552 8.10134 10.6945 8.362L7.41053 11.646L6.03653 9.77267C5.81853 9.476 5.40119 9.41134 5.10453 9.62934C4.80786 9.84734 4.74319 10.2647 4.96119 10.5613L6.79453 13.0607Z" />
                <path d="M14 2H13.3333V0.666667C13.3333 0.298667 13.0347 0 12.6667 0H12C11.632 0 11.3333 0.298667 11.3333 0.666667V2H4.66667V0.666667C4.66667 0.298667 4.368 0 4 0H3.33333C2.96533 0 2.66667 0.298667 2.66667 0.666667V2H2C0.897333 2 0 2.89733 0 4V14C0 15.1027 0.897333 16 2 16H14C15.1027 16 16 15.1027 16 14V4C16 2.89733 15.1027 2 14 2ZM14.6667 14C14.6667 14.3673 14.3673 14.6667 14 14.6667H2C1.63267 14.6667 1.33333 14.3673 1.33333 14V6.69333H14.6667V14Z" />
              </g>
            </svg>
          </>
        );
        break;
      case "week":
        iconSrc = (
          <>
            <svg width="13" height="13" viewBox="0 0 16 17">
              <g>
                <path d="M13.8348 1.25921H12.6928C12.6748 0.930213 12.4048 0.668213 12.0713 0.668213C11.7378 0.668213 11.4678 0.930213 11.4498 1.25921H8.62127C8.60327 0.930213 8.33327 0.668213 7.99977 0.668213C7.66627 0.668213 7.39627 0.930213 7.37827 1.25921H6.21477C5.87977 1.25921 5.57727 1.50671 5.55477 1.83921C5.53052 2.19771 5.81702 2.50921 6.17727 2.50921H7.37827C7.39627 2.83821 7.66627 3.10021 7.99977 3.10021C8.33327 3.10021 8.60327 2.83821 8.62127 2.50921H11.4498C11.4678 2.83821 11.7378 3.10021 12.0713 3.10021C12.4048 3.10021 12.6748 2.83821 12.6928 2.50921H13.8348C14.1973 2.50921 14.4898 2.80171 14.4898 3.16421V4.65171H1.50977V3.16421C1.50977 2.80171 1.80227 2.50921 2.16477 2.50921H3.30677C3.32477 2.83821 3.59477 3.10021 3.92827 3.10021C4.26177 3.10021 4.53177 2.83821 4.55227 2.50921V2.48596C4.55227 2.48221 4.55327 2.47896 4.55327 2.47521V1.29321C4.55327 1.28946 4.55227 1.28621 4.55227 1.28246C4.55227 1.28246 4.55227 1.25921 4.55002 1.25921C4.53202 0.930213 4.26202 0.668213 3.92852 0.668213C3.59502 0.668213 3.32477 0.930213 3.30677 1.25921H2.16477C1.11227 1.25921 0.259766 2.11171 0.259766 3.16421V14.4267C0.259766 15.4792 1.11227 16.3317 2.16477 16.3317H13.8348C14.8873 16.3317 15.7398 15.4792 15.7398 14.4267V7.52671C15.7398 7.18171 15.4598 6.90171 15.1148 6.90171C14.7715 6.90171 14.4898 7.18321 14.4898 7.52671V14.4267C14.4898 14.7892 14.1973 15.0817 13.8348 15.0817H2.16477C1.80227 15.0817 1.50977 14.7892 1.50977 14.4267V5.90171H14.8798C15.3548 5.90171 15.7398 5.51671 15.7398 5.04171C15.7398 4.41596 15.7398 3.79021 15.7398 3.16421C15.7398 2.11171 14.8873 1.25921 13.8348 1.25921Z" />
                <path d="M7.73656 13.6458C7.65706 13.6458 7.57581 13.6303 7.49781 13.5981C7.17906 13.4663 7.02731 13.1006 7.15931 12.7816L9.01031 8.55981H6.69531C6.35006 8.55981 6.07031 8.28006 6.07031 7.93481C6.07031 7.58956 6.35006 7.30981 6.69531 7.30981H9.94531C10.1541 7.30981 10.3488 7.41406 10.4648 7.58756C10.5808 7.76106 10.6026 7.98106 10.5226 8.17381L8.31431 13.2596C8.21481 13.5001 7.98206 13.6458 7.73656 13.6458Z" />
              </g>
            </svg>
          </>
        );
        break;
      case "month":
        iconSrc = (
          <>
            <svg width="13" height="13" viewBox="0 0 16 16">
              <g>
                <path d="M2.41836 16H11.805C11.9621 16 12.1132 15.9378 12.2228 15.8252L15.5294 12.5185C15.642 12.406 15.7042 12.2578 15.7042 12.1008V3.30665C15.7042 2.13628 14.7531 1.18516 13.5828 1.18516H12.7413V0.592582C12.7413 0.266652 12.4746 0 12.1487 0C11.8228 0 11.5561 0.266652 11.5561 0.592582V1.18516H4.44504V0.592582C4.44504 0.266652 4.17834 0 3.85241 0C3.52648 0 3.25983 0.266652 3.25983 0.592582V1.18516H2.41836C1.24799 1.18516 0.296875 2.13628 0.296875 3.30665V13.8785C0.296875 15.0489 1.24799 16 2.41836 16ZM11.8524 14.5215V12.6163C11.8524 12.5215 11.9295 12.4444 12.0243 12.4444H13.9295L11.8524 14.5215ZM1.48209 3.30665C1.48209 2.79111 1.90282 2.37038 2.41836 2.37038H3.25983V2.96296C3.25983 3.28889 3.52648 3.55554 3.85241 3.55554C4.17834 3.55554 4.445 3.28889 4.445 2.96296V2.37038H11.5561V2.96296C11.5561 3.28889 11.8228 3.55554 12.1487 3.55554C12.4746 3.55554 12.7413 3.28889 12.7413 2.96296V2.37038H13.5828C14.0983 2.37038 14.519 2.79111 14.519 3.30665V11.2592H12.0242C11.2746 11.2592 10.6672 11.8667 10.6672 12.6163V14.8148H2.41836C1.90282 14.8148 1.48209 14.3941 1.48209 13.8786V3.30665Z" />
                <path d="M4.59229 6.81471C5.00138 6.81471 5.33302 6.48307 5.33302 6.07398C5.33302 5.66489 5.00138 5.33325 4.59229 5.33325C4.1832 5.33325 3.85156 5.66489 3.85156 6.07398C3.85156 6.48307 4.1832 6.81471 4.59229 6.81471Z" />
                <path d="M7.85206 6.81471C8.26115 6.81471 8.59278 6.48307 8.59278 6.07398C8.59278 5.66489 8.26115 5.33325 7.85206 5.33325C7.44296 5.33325 7.11133 5.66489 7.11133 6.07398C7.11133 6.48307 7.44296 6.81471 7.85206 6.81471Z" />
                <path d="M11.1118 6.81471C11.5209 6.81471 11.8525 6.48307 11.8525 6.07398C11.8525 5.66489 11.5209 5.33325 11.1118 5.33325C10.7027 5.33325 10.3711 5.66489 10.3711 6.07398C10.3711 6.48307 10.7027 6.81471 11.1118 6.81471Z" />
                <path d="M4.59229 9.77784C5.00138 9.77784 5.33302 9.44621 5.33302 9.03711C5.33302 8.62802 5.00138 8.29639 4.59229 8.29639C4.1832 8.29639 3.85156 8.62802 3.85156 9.03711C3.85156 9.44621 4.1832 9.77784 4.59229 9.77784Z" />
                <path d="M7.85206 9.77784C8.26115 9.77784 8.59278 9.44621 8.59278 9.03711C8.59278 8.62802 8.26115 8.29639 7.85206 8.29639C7.44296 8.29639 7.11133 8.62802 7.11133 9.03711C7.11133 9.44621 7.44296 9.77784 7.85206 9.77784Z" />
                <path d="M11.1118 9.77784C11.5209 9.77784 11.8525 9.44621 11.8525 9.03711C11.8525 8.62802 11.5209 8.29639 11.1118 8.29639C10.7027 8.29639 10.3711 8.62802 10.3711 9.03711C10.3711 9.44621 10.7027 9.77784 11.1118 9.77784Z" />
                <path d="M4.59229 12.7407C5.00138 12.7407 5.33302 12.4091 5.33302 12C5.33302 11.5909 5.00138 11.2593 4.59229 11.2593C4.1832 11.2593 3.85156 11.5909 3.85156 12C3.85156 12.4091 4.1832 12.7407 4.59229 12.7407Z" />
                <path d="M7.85206 12.7407C8.26115 12.7407 8.59278 12.4091 8.59278 12C8.59278 11.5909 8.26115 11.2593 7.85206 11.2593C7.44296 11.2593 7.11133 11.5909 7.11133 12C7.11133 12.4091 7.44296 12.7407 7.85206 12.7407Z" />
              </g>
            </svg>
          </>
        );
        break;
      default:
        iconSrc = (
          <>
            <svg width="13" height="13" viewBox="0 0 16 16">
              <path d="M3.36154 1.33374C2.24969 1.33374 1.33398 2.24944 1.33398 3.3613V5.3052C1.33398 6.41705 2.24969 7.33276 3.36154 7.33276H5.30544C6.4173 7.33276 7.333 6.41705 7.333 5.3052V3.3613C7.333 2.24944 6.4173 1.33374 5.30544 1.33374H3.36154ZM3.36154 2.6674H5.30544C5.70169 2.6674 5.99934 2.96505 5.99934 3.3613V5.3052C5.99934 5.70145 5.70169 5.99909 5.30544 5.99909H3.36154C2.96529 5.99909 2.66765 5.70145 2.66765 5.3052V3.3613C2.66765 2.96505 2.96529 2.6674 3.36154 2.6674Z" />
              <path d="M3.36154 8.66626C2.24969 8.66626 1.33398 9.58196 1.33398 10.6938V12.6377C1.33398 13.7496 2.24969 14.6653 3.36154 14.6653H5.30544C6.4173 14.6653 7.333 13.7496 7.333 12.6377V10.6938C7.333 9.58196 6.4173 8.66626 5.30544 8.66626H3.36154ZM3.36154 9.99992H5.30544C5.70169 9.99992 5.99934 10.2976 5.99934 10.6938V12.6377C5.99934 13.034 5.70169 13.3316 5.30544 13.3316H3.36154C2.96529 13.3316 2.66765 13.034 2.66765 12.6377V10.6938C2.66765 10.2976 2.96529 9.99992 3.36154 9.99992Z" />
              <path d="M10.6936 1.33374C9.58172 1.33374 8.66602 2.24944 8.66602 3.3613V5.3052C8.66602 6.41705 9.58172 7.33276 10.6936 7.33276H12.6375C13.7493 7.33276 14.665 6.41705 14.665 5.3052V3.3613C14.665 2.24944 13.7493 1.33374 12.6375 1.33374H10.6936ZM10.6936 2.6674H12.6375C13.0337 2.6674 13.3314 2.96505 13.3314 3.3613V5.3052C13.3314 5.70145 13.0337 5.99909 12.6375 5.99909H10.6936C10.2973 5.99909 9.99968 5.70145 9.99968 5.3052V3.3613C9.99968 2.96505 10.2973 2.6674 10.6936 2.6674Z" />
              <path d="M10.6936 8.66626C9.58172 8.66626 8.66602 9.58196 8.66602 10.6938V12.6377C8.66602 13.7496 9.58172 14.6653 10.6936 14.6653H12.6375C13.7493 14.6653 14.665 13.7496 14.665 12.6377V10.6938C14.665 9.58196 13.7493 8.66626 12.6375 8.66626H10.6936ZM10.6936 9.99992H12.6375C13.0337 9.99992 13.3314 10.2976 13.3314 10.6938V12.6377C13.3314 13.034 13.0337 13.3316 12.6375 13.3316H10.6936C10.2973 13.3316 9.99968 13.034 9.99968 12.6377V10.6938C9.99968 10.2976 10.2973 9.99992 10.6936 9.99992Z" />
            </svg>
          </>
        );
        break;
    }
    return iconSrc;
  };

  return (
    <ListRequirementsStyle className="container">
      <div className="header-content">
        <h2 className="title">Danh sách yêu cầu</h2>
      </div>
      <SpinStyle
        spinning={isLoading}
        wrapperClassName="root-spin"
        className="root-spin-component"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="content">
          <div className="filter-box">
            <div className="right-filter">
              <div className="radio-filter">
                {PERIOD.map((item, index) => (
                  <div
                    key={index}
                    className={classNames("item-filter", {
                      active: item.value === filterParams.filter
                    })}
                    onClick={() => onChangePeriod(item)}
                  >
                    <div className="content-item">
                      <div className="icon-filter">{getIcon(item.value)}</div>
                      <div className="label-filter">{item.label}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {listRequestsFilter?.data?.length ? (
            <div className="data-table">
              <Table
                dataSource={listRequestsFilter?.data}
                columns={columns}
                pagination={false}
                expandRowByClick
              />
              <Pagination
                total={listRequestsFilter?.total}
                current={currentPage}
                pageSize={10}
                onChange={onChangePage}
              />
            </div>
          ) : (
            <Empty
              className="empty-content"
              isShowCreate={false}
              contentText="Chưa có yêu cầu nào trong danh sách"
            />
          )}
        </div>
        <AcceptModal
          isModalAcceptOpen={isModalAcceptOpen}
          handleCancel={() => setIsModalAcceptOpen(false)}
          handleOk={() => handleAcceptOk()}
          request={request}
        />
        <RejectModal
          isModalRejectOpen={isModalRejectOpen}
          handleCancel={() => setIsModalRejectOpen(false)}
          handleOk={() => handleRejectOk()}
          request={request}
        />
      </SpinStyle>
    </ListRequirementsStyle>
  );
};

export default ListRequirementsScreen;
