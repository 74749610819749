import { LOCAL_STORAGE_KEY } from 'src/constants';

export const setUserData = (user: Auth.MeInfo) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, user.accessToken);
  localStorage.setItem(LOCAL_STORAGE_KEY.EMAIL, user.email);
};
export const logout = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEY.EMAIL);
  window.location.replace('/login');
//   authStore.logout();
//   channel.postMessage('LOGOUT');
};
