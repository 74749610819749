/* eslint-disable no-console */
import React, { useState } from 'react';
import { Avatar, Upload, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import type { RcFile, UploadChangeParam } from 'antd/es/upload';

const AvatarUploader: React.FC = () => {
  const [imageUrl, setImageUrl] = useState<string | null>('https://cdn.pixabay.com/photo/2016/02/10/21/59/landscape-1192669_1280.jpg');

  const beforeUpload = (file: RcFile) => {
    console.log('before upload', file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      void message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      void message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageUrl(reader.result as string));
      reader.readAsDataURL(info.file.originFileObj as RcFile);
    }
  };

  return (
    <div>
      <Upload
        name="avatar"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        <Avatar
          size={150}
          icon={<UserOutlined />}
          src={imageUrl}
          style={{ cursor: 'pointer' }}
        />
      </Upload>
    </div>
  );
};

export default AvatarUploader;
