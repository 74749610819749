import React from 'react';
import { Form, Input } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { InputProps } from 'antd/lib/input';
import { FieldInputProps, FormikTouched, FormikErrors } from 'formik';
import styled from 'styled-components';

const InputStyle = styled.div`
  .form-control {
    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper:hover,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      background-color: #272727;
      border: 1px solid #342c24;
      background: #272727;
      color: #ffffff;
    }
    .ant-input-password-icon.anticon {
      color: #ead38e;
    }
    .wrap-form {
      margin-top: 20px;
    }
    .ant-input {
      background-color: #272727;
      background: #272727;
      color: #ffffff;
    }
    .ant-form-item-has-error .ant-input {
      background: #272727;
    }
    .ant-input:hover {
      border-color: #40a9ff;
    }
    .ant-input:focus {
      border-color: #342c24;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
`;

type Props = {
  label?: string;
  field: FieldInputProps<any>;
  inputProps?: InputProps;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  setFieldValue?: (field: string, value: any) => any;
  pattern?: any;
} & Omit<FormItemProps, 'children'>;

const PasswordField: React.FC<Props> = ({ label, touched, error, field, inputProps, pattern, ...rest }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (pattern) {
      if (e.target.value === '' || pattern.test(e.target.value)) {
        field.onChange(e);
      }
    } else {
      field.onChange(e);
    }
  };

  return (
    <InputStyle>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={error && touched ? 'error' : ''}
        help={(touched && error) as string}
        {...rest}
      >
        <Input.Password {...field} {...inputProps} style={{ fontSize: 'inherit' }} onChange={onChange} />
      </Form.Item>
    </InputStyle>
  );
};

export default PasswordField;
