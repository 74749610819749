import React, { useState } from "react";
import NewsStyle from "./NewsStyle";
import { Col, Form, message } from "antd";
import { InputField, QuillNews } from "src/components/form";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button } from "src/components";
import { createNew } from "src/stores/screens/new/new.action";
import { useAppDispatch } from "src/stores";
import { useNavigate } from "react-router-dom";

const NewCreateScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initialValues = {
    title: "",
    content: ""
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required("Tiêu đề là bắt buộc"),
    content: yup.string().required("Nội dung là bắt buộc"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value: any) => {
      handleRegister(value);
    },
  });

  const onRegisterSuccess = (): void => {
    message.success('Đăng ký tin tức thành công!');
    navigate('/news');
  };


  const handleRegister = (value: any): void => {
    void dispatch(createNew({ data: value, callback: onRegisterSuccess }));
  };

  const { setFieldValue } = formik;

  return (
    <NewsStyle className="container">
      <div className="header-content">
        <h2 className="title">Quản lý tin tức</h2>
      </div>
      <div className="content">
        <Col xs={24} className="box-form">
          <Form
            name="news"
            className="news-form"
            onFinish={() => formik.handleSubmit()}
          >
            <div style={{ color: '#EAD38E', fontSize: '18px' }}>Tạo bài viết</div>
            <InputField
              label="Tiêu đề bài viết"
              field={formik.getFieldProps("title")}
              setFieldValue={setFieldValue}
              className="form-control form-title"
              inputProps={{
                size: "middle",
                placeholder: "Nhập tiêu đề",
              }}
              error={formik.errors.title}
              touched={formik.touched.title}
            />
            <QuillNews
              field={formik.getFieldProps("content")}
              setFieldValue={setFieldValue}
              className="form-control chat-box"
              touched={formik.touched.content}
              error={formik.errors.content}
              placeholder="Nội dung"
            />
            <div style={{ gap: '10px', display: 'flex', justifyContent: 'end' }}>
              <Button buttonType="cancel" width={100} onClick={() => navigate('/news')}>
                Quay về
              </Button>
              <Button className="btn-submit" htmlType="submit">
                Tạo bài viết
              </Button>
            </div>
          </Form>
        </Col>
      </div>
    </NewsStyle>
  );
};

export default NewCreateScreen;
