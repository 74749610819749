export const APPOINTMENT_STATUS = [
  { value: "requesting", label: "Chờ xác nhận", color: "#52c41a" },
  { value: "initiated", label: "Đã khởi tạo", color: "#EAD38E" },
  { value: "information_provided", label: "Đã cung cấp thông tin", color: "#ff0000" },
  { value: "consulted", label: "Đã tư vấn", color: "#1890ff" },
  { value: "documents_sent", label: "Đã gửi Tài liệu", color: "#faad14" },
  { value: "completed", label: "Hoàn thành", color: "#ffffff" },
]

export const REQUEST_STATUS = [
  { label: "Tất cả", value: "" },
  { value: "approve", label: "Xác nhận", color: "#52c41a" },
  { value: "pending", label: "Chờ xác nhận", color: "#EAD38E" },
  { value: "reject", label: "Từ chối", color: "#ff0000" }
]
