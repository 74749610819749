import React from "react";
import { Button, Image } from "src/components";
import { EMPTY } from "src/assets/images";
import styled from "styled-components";

interface CommonImageProps {
  onCreate?: () => void;
  isShowCreate?: boolean;
  buttonText?: string;
  contentText: string;
  className?: string;
}

const Empty: React.FC<CommonImageProps> = ({
  onCreate,
  isShowCreate,
  buttonText,
  contentText,
  className
}) => {
  return (
    <EmptyStyle className={className}>
      <div className="box-empty-content">
        <Image className="img-empty" width={120} height={120} src={EMPTY} />
        <p className="empty-text">{contentText}</p>
        {isShowCreate && <Button className="btn-create" onClick={onCreate}>{buttonText}</Button>}
      </div>
    </EmptyStyle>
  );
};

export default Empty;

const EmptyStyle = styled.div`
  .box-empty-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    .img-empty {
      height: auto;
    }
    .empty-text {
      color: #ffffff;
    }
    .btn-create {
      margin: 0 auto;
    }
  }
`;
