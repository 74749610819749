import styled from "styled-components";

export const AppointmentStyle = styled.div`
  .ant-card-body {
    padding-top: 0 !important;
  }
  .ant-card-head-title {
    text-align: start !important;
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    .title {
      color: #ead38e;
    }
  }
  .content-right {
    border-left: 1px solid #4e473e;
  }

  .btn-submit {
    display: flex;
    justify-content: flex-end;
  }

  .text-white {
    font-size: 16px;
    color: #ffffff;
  }
  .save-btn {
    position: absolute;
    bottom: 0;
    right: 16px;
  }
  .empty-content {
    margin-top: 30px;
    height: calc(100vh - 300px);
  }
  .data-table {
    margin-top: 30px;
    height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-table-wrapper {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #745811;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #715e2d;
      }
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      .ant-table {
        .ant-table-thead {
          th {
            background-color: #322b1c;
            color: #ffffff;
            border: none;
          }
        }
        .ant-table-tbody {
          td {
            background-color: #222222;
            border: none;
            color: #ffffff;
            .name-box {
              display: flex;
              gap: 10px;
              .ant-image-img {
                border-radius: 9999px !important;
              }
              p {
                margin: auto 0;
              }
            }
          }
          .action-box {
            display: flex;
            gap: 5px;
            .ant-btn {
              color: #ffffff;
              background-color: transparent;
              border: none;
            }
          }
          .ant-table-row {
            cursor: pointer;
          }
          .ant-empty-description {
            color: #ffffff;
          }
        }
      }
    }
  }
`;

export const CalendarContentStyle = styled.div`
  .title {
    font-size: 16px;
    color: #ead38e;
  }
  .description {
    font-size: 14px;
    color: #ffffff;
  }
`;

export const CalendarStyle = styled.div`
  .custom-calendar {
    background: transparent;
    font-size: 16px;
    width: 100%;
    border: unset;
  }
  .react-calendar__year-view__months {
    color: #ffffff;
  }
  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: unset;
    }
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: unset;
  }

  .react-calendar__navigation {
    color: #ead38e;
  }

  .react-calendar__month-view__weekdays,
  .react-calendar__month-view__days__day {
    color: #ffffff;
    font-size: 16px;
  }

  .react-calendar__tile {
    background: transparent;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
  }

  .react-calendar__tile--now {
    color: yellow;
    background: transparent;
    font-weight: bold;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar button:enabled:hover {
    background: none;
    background-color: unset;
    position: relative;
    line-height: 17px;
    abbr {
      background: #ead38e;
      color: #342c24;
      border-radius: 50%;
      border: none;
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .react-calendar__month-view__days__day {
    height: 50px;
    width: 50px;
  }

  .react-calendar__tile:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
`;
