import React from "react";
import { Form, Radio } from "antd";
import { FormItemProps } from "antd/lib/form";
import { RadioChangeEvent, RadioGroupProps } from "antd/lib/radio";
import { FieldInputProps, FormikTouched, FormikErrors } from "formik";
import styled from "styled-components";

const RadioStyle = styled.div`
  .ant-form-item-control {
    .ant-radio-wrapper {
      color: #ead38e;
      .ant-radio-checked .ant-radio-inner {
        border: 1px solid #342c24;
        background-color: #EAD38E;
        &::after {
          background-color: #342C24;
        }
      }
    }
  }
`;

export type RadioGroupFieldProps = {
  field: FieldInputProps<any>;
  optionsSelect: Array<{ label: string; value: number | string }>;
  label?: string;
  radioProps?: RadioGroupProps;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  onChange?: (e: RadioChangeEvent) => void;
  disabled?: boolean;
  setFieldValue: (field: string, value: any) => void;
  optionType?: "default" | "button";
} & Omit<FormItemProps, "children">;

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
  label,
  touched,
  error,
  field,
  radioProps,
  optionsSelect,
  disabled,
  setFieldValue,
  optionType,
  ...rest
}) => {
  const onChange = (e: any): void => {
    setFieldValue(field.name, e.target.value);
  };
  return (
    <RadioStyle>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={error && touched ? "error" : ""}
        help={(touched && error) as string}
        {...rest}
      >
        <Radio.Group
          {...field}
          {...radioProps}
          optionType={optionType}
          options={optionsSelect}
          onChange={onChange}
          disabled={disabled}
        />
      </Form.Item>
    </RadioStyle>
  );
};

export default RadioGroupField;
