import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppointmentMessagesStyle from "./AppointmentMessagesStyle";
import { Button, Empty } from "src/components";
import { Table } from "antd";
import { WORK_RANGE } from "src/constants";
import { Pagination } from "src/components";

import { useAppDispatch, useAppSelector } from "src/stores";
import { getListAppointmentsByNewMessage } from "src/stores/screens/appointment/appointment.action";
import { formatDateDDMMYYYY } from "src/utils";
import { SpinStyle } from "src/components/style";

const ListRequirementsScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initFilterParams = {
    search: "",
    requestDate: ""
  };

  const [filter, setFilter] = useState(initFilterParams);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchAppointment(initFilterParams);
  }, []);

  const { listAppointmentsByNewMessage, isLoading } = useAppSelector(
    (state) => state.appointment
  );

  const isImageMessage = (htmlText: string): boolean => {
    const base64ImageRegex =
      /<img\s+[^>]*src=["']data:image\/(jpeg|png|gif|webp);base64,[^"']*["'][^>]*>/i;
    return base64ImageRegex.test(htmlText);
  };

  const columns: any = [
    {
      title: "Tên khách hàng",
      dataIndex: "username",
      key: "username",
      width: 150,
      onCell: () => ({ style: { minWidth: 150 } }),
      render: (username: any, record: any) => {
        return (
          <div
            style={{
              color: "#EAD38E",
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "160px",
              whiteSpace: "nowrap"
            }}
          >
            {username || record?.user?.username}
          </div>
        );
      }
    },
    {
      title: "Thời gian",
      key: "requestTime",
      dataIndex: "requestTime",
      align: "center",
      width: 200,
      onCell: () => ({ style: { minWidth: 200 } }),
      render: (item: any, record: any) => {
        return (
          <div>
            {!!item ? (
              <>
                <div>{getLableFrame(item)}</div>
                <div>{formatDateDDMMYYYY(record.requestDate)}</div>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      }
    },
    {
      title: "Tin nhắn",
      dataIndex: "latestConsultation",
      key: "latestConsultation",
      width: 300,
      onCell: () => ({ style: { minWidth: 300 } }),
      render: (_: any, record: any) => {
        return isImageMessage(record.consultationContent) ? (
          <div>Tin nhắn hình ảnh</div>
        ) : (
          <div
            className="lastest-messages"
            dangerouslySetInnerHTML={{
              __html: record.consultationContent ?? ""
            }}
          ></div>
        );
      }
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 100,
      onCell: () => ({ style: { minWidth: 100 } }),
      render: (_item: any, record: any) => (
        <div className="action-box">
          {!record?.isParent && (
            <Button onClick={(e) => onClickConsultation(e, record)}>
              Tư vấn
            </Button>
          )}
        </div>
      )
    }
  ];

  const onClickConsultation = (e: any, record: any) => {
    e.stopPropagation();
    navigate("/consultation/" + record?.appointmentId);
  };

  const getLableFrame = (frame: string) => {
    return WORK_RANGE.find((i) => i.value === frame)?.label || "-";
  };

  const fetchAppointment = async (params = filter, page: number = 1) => {
    const paramsQuery = cleanFilter({ ...params, page: page });
    void (await dispatch(
      getListAppointmentsByNewMessage({ data: paramsQuery })
    ));
    setCurrentPage(page);
    setFilter(params);
  };

  const cleanFilter = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === "") delete obj[key];
    });
    return obj;
  };

  const onChangePage = (value: number) => {
    fetchAppointment(filter, value);
  };

  // const onChangeDate = (_e: any, date: string) => {
  //   if (date) {
  //     const formattedDate = formatDateYYYYMMDD(date) || "";
  //     fetchAppointment({ ...filter, requestDate: formattedDate });
  //   } else {
  //     fetchAppointment({ ...filter, requestDate: "" });
  //   }
  // };

  return (
    <AppointmentMessagesStyle className="container">
      <div className="header-content">
        <h2 className="title">Lịch hẹn tư vấn - Tin nhắn mới nhất</h2>
      </div>
      <SpinStyle
        spinning={isLoading}
        wrapperClassName="root-spin"
        className="root-spin-component"
      >
        <div className="content">
          {/* <div className="filter-box">
            <div className="search-box">
              <Input
                placeholder="Tìm khách hàng"
                prefix={<SearchOutlined />}
                onChange={(e) =>
                  setFilter({ ...filter, search: e.target.value })
                }
                onPressEnter={() => fetchAppointment()}
              />
              <Button onClick={() => fetchAppointment()}>Tìm</Button>
            </div>
            <div className="right-filter">
              <DatePicker
                format={DATE_FORMAT}
                placeholder="Chọn ngày"
                className="filter-date"
                onChange={(e, date) => onChangeDate(e, date)}
              />
            </div>
          </div> */}
          {listAppointmentsByNewMessage?.data?.length ? (
            <div className="data-table">
              <Table
                dataSource={listAppointmentsByNewMessage?.data || []}
                columns={columns}
                pagination={false}
                expandRowByClick
              />
              <Pagination
                total={listAppointmentsByNewMessage?.total}
                current={currentPage}
                pageSize={10}
                onChange={onChangePage}
              />
            </div>
          ) : (
            <Empty
              className="empty-content"
              isShowCreate={false}
              contentText="Chưa có lịch hẹn nào trong danh sách"
            />
          )}
        </div>
      </SpinStyle>
    </AppointmentMessagesStyle>
  );
};

export default ListRequirementsScreen;
