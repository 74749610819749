import React from "react";
import { Button as ButtonAnt } from "antd";
import styled from "styled-components";

type ButtonVariant = "submit" | "cancel" | "default" | "none";

interface CommonButtonProps {
  buttonType?: ButtonVariant;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
  htmlType?: "button" | "submit" | "reset";
  width?: number;
  disabled?: boolean;
}

const StyledButton = styled(ButtonAnt)<{
  buttonType: ButtonVariant;
  width?: number;
}>`
  &.ant-btn {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    align-items: center;
    display: flex;
    justify-content: center;
    ${(props) =>
      props.buttonType === "submit" &&
      `
      background-color: #1890ff;
      color: white;
      border: none;
    `}
    ${(props) =>
      props.buttonType === "none" &&
      `
      background-color: rgb(34, 32, 30);
      color: #ead38e;
      border: 1px solid #ead38e;
    `}
    ${(props) =>
      props.buttonType === "cancel" &&
      `
      background-color: #DB2C1F;
      color: white;
      border: none;
    `}
    ${(props) =>
      props.buttonType === "default" &&
      `
      background: #ead38e;
      color: black;
      border: none;
    `}
    &:disabled {
      ${(props) =>
        props.buttonType === "default" &&
        `
      background: #867956;
      color: #2B2622;
      border: none;
    `}
      ${(props) =>
        props.buttonType === "cancel" &&
        `
      background: #994e48;
      color: #b3b3b3;
      border: none;
    `}
    }

    &:hover {
      opacity: 0.9;
    }
    &.btn-right {
      float: right;
    }
    width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  }
`;

const Button: React.FC<CommonButtonProps> = ({
  buttonType = "default",
  onClick,
  children,
  className,
  htmlType = "button",
  width,
  disabled,
  ...props
}) => {
  return (
    <StyledButton
      buttonType={buttonType}
      onClick={onClick}
      className={className}
      htmlType={htmlType}
      width={width}
      disabled={disabled}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
