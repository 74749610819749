import { Tag } from 'antd';
import styled from 'styled-components';

export const TagComponentStyle = styled(Tag)`
  &.ant-tag {
    font-size: 12px;
    padding: 4px 10px;
    ${(props) =>
    props.color === 'success' &&
    `
    `}
    &.moc {
      background-color: #033903;
      border: 1px solid #52c41a;
      color: #52c41a;
    }
    &.kim {
      background-color: #515115;
      border: 1px solid #ffff00;
      color: #ffff00;
    }
    &.thuy {
      background-color: #0b294b;
      border: 1px solid #40a9ff;
      color: #40a9ff;
    }
    &.hoa {
      background-color: #350c0e;
      border: 1px solid #d9363e;
      color: #d9363e;
    }
    &.tho {
      background-color: rgb(49 36 13);
      color: rgb(181 127 19);
      border: 1px solid rgb(181 127 19);
    }
  }
`;
