import React, { useEffect, useState } from "react";
import { Col, Form, Layout, Row } from "antd";
import { Button } from "src/components";
import { Image } from "src/components";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { LARGE_LOGO, DARK_BACKGROUND } from "src/assets/images";
import { InputField } from "src/components/form";

const { Content } = Layout;

const VerifyEmailScreen: React.FC = () => {
  const navigate = useNavigate();
  const TIME_SEND_CODE = 60;

  const [seconds, setSeconds] = useState(TIME_SEND_CODE);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isActive, seconds]);

  useEffect(() => {
    onSendCode();
  }, []);

  const onSendCode = () => {
    setSeconds(TIME_SEND_CODE);
    setIsActive(true);
  };

  const validationSchema = yup.object().shape({
    code: yup.string().required("AUTHENTICATION CODE REQUIRED")
  });

  const initialValues = {
    code: ""
  };

  const onVerifySuccess = (): void => {
    // handle verify code
    navigate("/login");
  };

  const handleVerify = (value): void => {
    console.log(value);
    onVerifySuccess();
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleVerify(value);
    }
  });

  const { setFieldValue } = formik;

  return (
    <VerifyEmailScreenStyle>
      <Row gutter={16} style={{ height: "100%" }}>
        <Col
          xs={24}
          sm={13}
          className="box-logo"
          style={{
            backgroundImage: `url(${DARK_BACKGROUND})`
          }}
        >
          <div className="logo">
            <Image src={LARGE_LOGO} />
          </div>
          <div className="title">
            <p className="brand-name">Phong Thủy Nhật Minh</p>
            <p className="description">
              Đặt lịch tư vấn, xem quẻ, luận giải về phong thủy
            </p>
          </div>
        </Col>
        <Col xs={24} sm={11} className="box-form">
          <Form
            name="login"
            className="login-form"
            onFinish={() => formik.handleSubmit()}
          >
            <p className="title">Mã xác thực email</p>
            <p className="sub-title">
              Nhập mã xác thực được gửi tới email của bạn
            </p>
            <div className="label-authen">
              <p className="label">Mã xác thực</p>
              <>
                {seconds > 0 ? (
                  <p className="counter">Gửi lại mã ({seconds})s</p>
                ) : (
                  <p className="resend-code counter" onClick={onSendCode}>Gửi lại mã</p>
                )}
              </>
            </div>
            <InputField
              field={formik.getFieldProps("code")}
              setFieldValue={setFieldValue}
              className="form-control form-code"
              inputProps={{
                size: "middle",
                placeholder: "Nhập mã xác thực"
              }}
              error={formik.errors.code}
              touched={formik.touched.code}
            />
            <p className="desc-label">Mã xác thực có thời hạn trong {TIME_SEND_CODE}s</p>
            <Button className="btn-submit" htmlType="submit">
              Xác thực
            </Button>
          </Form>
        </Col>
      </Row>
    </VerifyEmailScreenStyle>
  );
};

export default VerifyEmailScreen;

const VerifyEmailScreenStyle = styled(Content)`
  @import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-row {
    height: 100%;
    .ant-form-item {
      margin-bottom: 20px;
      .ant-form-item-row {
        flex-direction: column;
      }
      .ant-form-item-label > label {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        color: #ead38e;
      }
    }
  }

  .box-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: "100%";
    background-size: "cover";
    background-position: "center";
    background-repeat: "repeat";
    padding: 50px;
    color: #ead38e;
    text-align: center;
    font-family: "Patrick Hand";
    .brand-name {
      font-size: 40px;
      line-height: 0;
    }
    .description {
      font-size: 28px;
    }
    .ant-image-img {
      height: 30%;
      width: 30%;
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 8% !important;
    background-color: #242424;
    .title {
      font-size: 32px;
      color: #ead38e;
      font-weight: bold;
    }
    .sub-title {
      font-size: 17px;
      color: #ffffff;
    }
    .label-authen {
      display: flex;
      justify-content: space-between;
      .label {
        color: #EAD38E;
      }
      .counter {
        color: #1890ff;
      }
      .resend-code {
        cursor: pointer;
      }
    }
    .desc-label {
      color: #CDC29A;
    }
  }

  .login-form {
    width: 100%;
    input.ant-input {
      border: none;
    }
  }

  .btn-submit {
    width: 100%;
  }
`;
