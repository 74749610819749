import React from "react";
import type { RouteObject } from "react-router-dom";

import { PrivateLayout } from "src/layouts";
import { NotFound } from "src/screens/NotFound";
import {
  ConsultationScreen,
  AppointmentScreen,
  ListRequirementsScreen,
  SettingAppointmentScreen,
  UserManagementScreen,
  NewsScreen,
  NewsCreateScreen,
  NewsUpdateScreen,
  AppointmentMessages
} from "src/screens/privateScreens";

// const SettingAppointmentScreen = React.lazy(
//   async () =>
//     await import("src/screens/privateScreens").then((module) => ({
//       default: module.SettingAppointmentScreen
//     }))
// );

const _privateRoutes: RouteObject[] = [
  {
    element: <PrivateLayout />,
    children: [
      { path: "/", element: <SettingAppointmentScreen /> },
      { path: "/setting_appointment", element: <SettingAppointmentScreen /> },
      { path: "/appointment/:id", element: <AppointmentScreen /> },
      { path: "/appointment_messages", element: <AppointmentMessages /> },
      { path: "/list_of_requirements", element: <ListRequirementsScreen /> },
      { path: "/user_management", element: <UserManagementScreen /> },
      { path: "/consultation/:id", element: <ConsultationScreen /> },
      { path: "/news", element: <NewsScreen /> },
      { path: "/news/create", element: <NewsCreateScreen /> },
      { path: "/new/update/:id", element: <NewsUpdateScreen /> },
      { element: <NotFound />, path: "*" }
    ]
  }
];

export default _privateRoutes;
