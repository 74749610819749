import { Pagination } from 'antd';
import styled from 'styled-components';

export const PaginationComponentStyle = styled(Pagination)`
&.ant-pagination {
  display: flex;
  justify-content: end;
  margin: 20px 0;
  .ant-pagination-item-link, .ant-pagination-item {
    border-radius: 12px;
    background-color: #272727;
    border: 1px solid #342C24;
    color: #ffffff;
    &:disabled {
      color: #95A0AF;
    }
    a {
      color: #ffffff;
    }
  }
  .ant-pagination-options, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    display: none;
  }
  .ant-pagination-item-active a {
    border-radius: 12px;
    color: #000000;
    background-color: #ead38e;
  }
}
`;
