import React from "react";
import StatusModalStyle from "./StatusModalStyle";
import { Button } from "src/components";
import { Form, message } from "antd";
import { SelectField } from "src/components/form";
import { APPOINTMENT_STATUS } from "src/constants";
import { useFormik } from "formik";
import { useAppDispatch } from "src/stores";
import { updateAppointmentStatus } from "src/stores/screens/appointment/appointment.action";

interface StatusModalProps {
  isModalStatusOpen: boolean;
  handleCancel: () => void;
  id?: string;
}

const StatusModal: React.FC<StatusModalProps> = ({
  isModalStatusOpen,
  handleCancel,
  id
}) => {
  const dispatch = useAppDispatch();

  const handleSubmit = async (value: { status: string }) => {
    if(!id) return;
    const res: any = await dispatch(
      updateAppointmentStatus({ data: { ...value, id: id } })
    );
    if (res?.error) {
      message.error("Cập nhật trạng thái thất bại");
    } else {
      message.success("Cập nhật trạng thái thành công");
      handleCancel();
    }
  };

  const initialValues = {
    status: "initiated"
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (value) => {
      handleSubmit(value);
    }
  });

  const { setFieldValue } = formik;

  return (
    <StatusModalStyle
      title="Thay đổi trạng thái"
      visible={isModalStatusOpen}
      onCancel={handleCancel}
      className="modal-status"
    >
      <Form
        name="login"
        className="setting-form"
        layout="vertical"
        onFinish={() => {
          formik.handleSubmit();
        }}
      >
        <div className="field-status">
          <p>Thay đổi trạng thái</p>
          <SelectField
            optionsSelect={APPOINTMENT_STATUS}
            field={formik.getFieldProps("status")}
            setFieldValue={setFieldValue}
            className="form-control status"
            style={{ width: "200px" }}
          />
        </div>
        <div className="button-footer">
          <Button buttonType="cancel" onClick={handleCancel} width={100}>
            Đóng
          </Button>
          <Button htmlType="submit" width={100}>
            Đồng ý
          </Button>
        </div>
      </Form>
    </StatusModalStyle>
  );
};

export default StatusModal;
