import { createAsyncThunk } from '@reduxjs/toolkit';

import USER_API from './user.api';

export const getListUsers = createAsyncThunk<any, any>(
  'user',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await USER_API.listUsersAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getDetailUser = createAsyncThunk<any, any>(
  'detail_user',
  async (data, { rejectWithValue }) => {
    try {
      const res = await USER_API.detailUserAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createUser = createAsyncThunk<any, any>(
  'create_user',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await USER_API.createUserAPI(data);
      return res
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
export const updateUser = createAsyncThunk<any, any>(
  'update_user',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await USER_API.updateUserAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteUser = createAsyncThunk<any, any>(
  'delete_user',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await USER_API.deleteUserAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const addMissingAttribute = createAsyncThunk<any, any>(
  'add_missing_attribute',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await USER_API.addMissingAttributeAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteMissingAttribute = createAsyncThunk<any, any>(
  'delete_missing_attribute',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await USER_API.deleteMissingAttributeAPI(data);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
