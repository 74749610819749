import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const USER_API = {
  listUsersAPI: async (params: any) => {
    const response = await new AxiosClient().get(`/user?${new URLSearchParams(params).toString()}`);
    return get(response, 'data', null);
  },
  detailUserAPI: async (id: any) => {
    const response = await new AxiosClient().get(`/user/${id}`);
    return get(response, 'data', null);
  },
  createUserAPI: async (params: any) => {
    const response = await new AxiosClient().post('/user', params);
    let data = get(response, 'data', null);
    return data
  },
  updateUserAPI: async (params: any) => {
    const response = await new AxiosClient().put('/user/' + params.id, params);
    const data = get(response, 'data', null);
    return data;
  },
  deleteUserAPI: async (id: any) => {
    const response = await new AxiosClient().delete('/user/' + id);
    const data = get(response, 'data', null);
    return data;
  },
  addMissingAttributeAPI: async (params: any) => {
    const response = await new AxiosClient().post('/user/missing-attribute', params);
    let data = get(response, 'data', null);
    return data
  },
  deleteMissingAttributeAPI: async (params: any) => {
    const response = await new AxiosClient().delete(`/user/${params.userId}/missing-attribute`, params);
    const data = get(response, 'data', null);
    return data;
  },
};

export default USER_API;
