import styled from 'styled-components';
import { Modal } from "src/components";

const FormModalStyle = styled(Modal)`
  &.modal-form {
    .ant-modal-body {
      padding-top: 0;
    }
    .form-password {
      .ant-form-item-control-input {
        border: 1px solid #8e886f;
        border-radius: 3px;
        .ant-input {
          border: none;
        }
      }
    }
    .birthdayLunar {
      .box-birthday {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
      .label {
        color: #ead38e;
        font-size: 14px;
      }
    }
    .ant-form-item-required {
      display: flex;
      flex-direction: row-reverse;
      justify-content: start;
      gap: 2px;
    }
  }
`;

export default FormModalStyle;
