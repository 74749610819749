import React, { useState, useEffect } from "react";
import UserManagementStyle from "./UserManagementStyle";
import { Input, Table, TableProps, message } from "antd";
import { Button, Empty, Pagination } from "src/components";
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import { SpinStyle } from "src/components/style";

import DeleteModal from "./delete-modal/DeleteModal";
import FormModal from "./form-modal/FormModal";
import InfoModal from "./form-info/FormInfo";
import { formatDateDDMMYYYY, getLunarYearName } from "src/utils";
import { getListUsers, deleteUser, getDetailUser } from "src/stores/screens/user/user.action";

import { useAppDispatch, useAppSelector } from "src/stores";

const UserManagementScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { listUsers, isLoading, detailUser } = useAppSelector((state) => state.user);

  const getNameYear = (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    return getLunarYearName(year);
  };

  const columns: TableProps<User.UserType>["columns"] = [
    {
      title: "Tên khách hàng",
      dataIndex: "username",
      key: "username",
      width: 180,
      onCell: () => ({ style: { minWidth: 180 } }),
      render: (attr: string, record: any) => {
        return (
          <div
            onClick={() => handleRowClick(record)}
            style={{
              color: "#EAD38E",
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "148px",
              whiteSpace: "nowrap"
            }}
          >
            {attr}
          </div>
        );
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 180,
      onCell: () => ({ style: { minWidth: 180 } }),
      render: (_, i: any) => {
        return (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "148px",
              whiteSpace: "nowrap"
            }}
          >
            {i?.email || " - "}
          </div>
        );
      }
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 160,
      onCell: () => ({ style: { minWidth: 160 } }),
      render: (_, i: any) => {
        return (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "118px",
              whiteSpace: "nowrap"
            }}
          >
            {i?.phoneNumber}
          </div>
        );
      }
    },
    {
      title: "Ngày sinh",
      dataIndex: "dateOfBirthLunar",
      key: "dateOfBirthLunar",
      width: 180,
      onCell: () => ({ style: { minWidth: 180 } }),
      render: (date) => {
        return date ? (
          <span style={{ whiteSpace: "nowrap" }}>{`${formatDateDDMMYYYY(
            date
          )} (${getNameYear(date)})`}</span>
        ) : (
          <></>
        );
      }
    },
    {
      title: "Facebook",
      dataIndex: "facebookId",
      key: "facebookId",
      width: 160,
      onCell: () => ({ style: { minWidth: 160 } }),
      render: (link: any) => {
        return !!link ? (
          <a
            style={{ color: "#ffffff", cursor: "pointer" }}
            className="link"
            target="_blank"
            href={link || ""}
            rel="noreferrer"
          >
            {link}
          </a>
        ) : (
          <span> - </span>
        );
      }
    },
    {
      title: "Action",
      key: "action",
      width: 130,
      onCell: () => ({ style: { minWidth: 130 } }),
      render: (_, record) => (
        <div className="action-box">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setUser(record);
              setIsModalDeleteOpen(true);
            }}
          >
            <DeleteOutlined />
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onEditUser(record);
            }}
          >
            <EditOutlined />
          </Button>
        </div>
      )
    }
  ];

  const initUser = {
    username: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: 0
  };

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [user, setUser] = useState<User.UserType>(initUser);
  const [filter, setFilter] = useState({ searchQuery: "" });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = (page = 1) => {
    const params = cleanFilter({ ...filter, page: page });
    void dispatch(getListUsers({ data: params }));
    setCurrentPage(page);
  };

  const cleanFilter = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === "") {
        delete obj[key];
      }
    });
    return obj;
  };

  const onCreateUser = () => {
    setUser(initUser);
    setIsModalFormOpen(true);
  };

  const onEditUser = async (user: User.UserType) => {
    await dispatch(getDetailUser(user.id));
    setUpUser(detailUser || user);
    setIsModalFormOpen(true);
  };

  const handleDeleteOk = async () => {
    const result: any = await dispatch(deleteUser({ data: user.id }));
    setIsModalDeleteOpen(false);
    if (result?.error) {
      message.error("Xóa người dùng thất bại");
    } else {
      message.success("Xóa người dùng thành công");
      fetchUser();
    }
  };

  const handleRowClick = async (user: User.UserType) => {
    await dispatch(getDetailUser(user.id));
    setUpUser(detailUser || user);
    setIsModalInfoOpen(true);
  };

  const setUpUser = (user: User.UserType) => {
    let selectedUser = { ...user };
    if (user.missingAttributes) {
      selectedUser = {
        ...user,
        missAttributes: user.missingAttributes.map(
          (item: any) => item.attribute
        )
      };
    }
    setUser(selectedUser);
  };

  const onOpenModalEdit = () => {
    setIsModalInfoOpen(false);
    setIsModalFormOpen(true);
  };

  const onOpenModalDelete = () => {
    setIsModalInfoOpen(false);
    setIsModalDeleteOpen(true);
  };

  const handleOkFormUser = () => {
    fetchUser();
    setIsModalFormOpen(false);
  };

  const onChangePage = (value: number) => {
    fetchUser(value);
  };

  return (
    <UserManagementStyle className="container">
      <SpinStyle
        spinning={isLoading}
        wrapperClassName="root-spin"
        className="root-spin-component"
      >
        <div className="header-content">
          <h2 className="title">Quản lý người dùng</h2>
          {!!listUsers?.data?.length && (
            <Button className="create-user" onClick={() => onCreateUser()}>
              Tạo người dùng
            </Button>
          )}
        </div>
        <div className="content">
          {listUsers?.data?.length ? (
            <>
              <div className="filter-box">
                <div className="search-box">
                  <Input
                    placeholder="Tìm khách hàng"
                    prefix={<SearchOutlined />}
                    onChange={(e) =>
                      setFilter({ ...filter, searchQuery: e.target.value })
                    }
                    onPressEnter={() => fetchUser()}
                  />
                  <Button onClick={() => fetchUser()}>Tìm</Button>
                </div>
                {/* <DatePicker format={DATE_FORMAT} placeholder="Chọn ngày" /> */}
              </div>
              <div className="data-table">
                <Table
                  dataSource={listUsers?.data || []}
                  columns={columns}
                  pagination={false}
                />
                <Pagination
                  total={listUsers?.total}
                  onChange={onChangePage}
                  current={currentPage}
                  pageSize={10}
                />
              </div>
            </>
          ) : (
            <Empty
              className="empty-content"
              isShowCreate
              contentText="Chưa có người dùng nào trong danh sách"
              buttonText="Tạo người dùng"
              onCreate={onCreateUser}
            />
          )}
        </div>
        <DeleteModal
          isModalDeleteOpen={isModalDeleteOpen}
          handleCancel={() => setIsModalDeleteOpen(false)}
          handleOk={() => handleDeleteOk()}
        />
        <FormModal
          isModalFormOpen={isModalFormOpen}
          user={user}
          handleCancel={() => setIsModalFormOpen(false)}
          handleOk={handleOkFormUser}
        />
        <InfoModal
          isVisibleModal={isModalInfoOpen}
          user={user}
          handleCancel={() => setIsModalInfoOpen(false)}
          onOpenModalEdit={() => onOpenModalEdit()}
          onOpenModalDelete={() => onOpenModalDelete()}
        />
      </SpinStyle>
    </UserManagementStyle>
  );
};

export default UserManagementScreen;
