import styled from 'styled-components';

const ListRequirementsStyle = styled.div`
  .header-content {
    display: flex;
    justify-content: space-between;

    .title {
      color: #EAD38E;
    }
  }
  .content {
    border: 1px solid #745811;
    border-radius: 5px;
    padding: 10px;
    .filter-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      gap: 10px;
      .search-box {
        display: flex;
        max-width: 300px;
        .ant-input-affix-wrapper {
          .ant-input-prefix {
            color: #342C24;
          }
          border: 1px solid #342C24;
          background-color: #272727;
          .ant-input {
            background-color: #272727;
            color: #ffffff;
          }
        }
      }
      .right-filter {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        .ant-radio-group-solid {
          border-radius: 10px;
          .ant-radio-button-wrapper {
          border: 1px solid #544D39;
          background-color: #272727;
            color: #EAD38E;
            &:hover {
              opacity: 0.6;
            }
            &:not(:first-child)::before {
              background-color: #272727;
            }
          }
          .ant-radio-button-wrapper-checked {
            background-color: #EAD38E;
            border-color: #544D39;
            color: #342C24;
            &:not(.ant-radio-button-wrapper-disabled)::before {
              background-color: #EAD38E;
            }
          }
        }
        .radio-filter {
          display: flex;
          border-radius: 10px;
          .item-filter {
            border: 1px solid #544d39;
            background-color: #272727;
            color: #ead38e;
            padding: 3px 11px;
            cursor: pointer;
            &:hover {
              opacity: 0.7;
            }
            .content-item {
              display: flex;
              gap: 4px;
              .icon-filter {
                padding-top: 1px;
                svg {
                  margin: auto 0;
                  height: 13px;
                  width: 13px;
                  path {
                    fill: #ead38e;
                  }
                }
              }
              .label-filter {
                margin: auto 0;
              }
            }
          }
          .active {
            background-color: #ead38e;
            border-color: #544d39;
            color: #342c24;
            .icon-filter svg {
              path {
                fill: #342c24 !important;
              }
            }
          }
        }
      }
      /* .ant-select {
        .ant-select-selector {
          background-color: #272727;
          border: 1px solid #342C24;
          text-overflow: ellipsis;
          .ant-select-selection-item, input::placeholder {
            color: #ffffff;
          }
        }
        .ant-select-arrow {
          color: #ffffff;
        }
      } */
      /* .ant-select-multiple {
        .ant-select-selection-item {
          background-color: #EAD38E;
          border: none;
          color: black !important;
          .ant-select-selection-item-remove {
            color: black !important;
          }
        }
      } */
      /* .box-switch {
        display: flex;
        gap: 10px;
        height: 100%;
        margin: auto 0;
        span {
          color: #ffffff
        }
        .ant-switch {
          background: #6A6A6A;
        }
        .ant-switch-checked {
          background: #EAD38E;
        }
      } */
    }
    .empty-content {
      margin-top: 30px;
      height: calc(100vh - 300px);
    }
    .data-table {
      margin-top: 30px;
      height: calc(100vh - 300px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .ant-table-wrapper {
        overflow: auto;
        &::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #745811;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: #715e2d;
        }
        &::-webkit-scrollbar-corner {
          background-color: transparent;
        }
        .ant-table {
          .ant-table-thead {
            th {
              background-color: #322B1C;
              color: #ffffff;
              border: none;
            }
          }
          .ant-table-tbody {
            .ant-table-row-expand-icon {
              background-color: #ead38e;
              &::before, &::after {
                color: #000000;
              }
            }
            td {
              background-color: #222222;
              border: none;
              color: #ffffff;
              .name-box {
                display: flex;
                gap: 10px;
                .ant-image-img {
                  border-radius: 9999px !important;
                }
                p {
                  margin: auto 0;
                }
              }
            }
            .action-box {
              display: flex;
              gap: 5px;
            }
            .ant-empty-description {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

`;

export default ListRequirementsStyle;
