import styled from 'styled-components';
import { Modal } from "src/components";

const FormModalStyle = styled(Modal)`
  &.modal-form {
    .form-password {
      .ant-form-item-control-input {
        border: 1px solid #8e886f;
        border-radius: 3px;
        .ant-input {
          border: none;
        }
      }
    }
    .birthdayLunar {
      .box-birthday {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        flex-direction: row;
      }
      .label {
        color: #ead38e;
        font-size: 14px;
      }
      .ant-select {
        width: 150px;
      }
    }
    .ant-form-item-required {
      display: flex;
      flex-direction: row-reverse;
      justify-content: start;
      gap: 2px;
    }
    @media only screen and (max-width: 700px) {
      .col-avatar {
        display: none;
      }
      .col-form {
        min-width: 100%;
      }
    }

    @media only screen and (max-width: 1024px) {
      .birthdayLunar .box-birthday {
        flex-direction: column;
        .ant-select {
          width: 100%;
        }
      }
    }
  }
`;

export default FormModalStyle;
