import React from "react";
import AcceptModalStyle from "./AcceptModalStyle";
import { Button } from "src/components";
import { WORK_RANGE } from "src/constants";
import { Form, message } from "antd";
import { formatDateDDMMYYYY } from "src/utils";
import { SwitchField } from "src/components/form";
import { useFormik } from "formik";
import { useAppDispatch } from "src/stores";
import { approveAppointmentRequests } from "src/stores/screens/appointment-request/appointment-request.action";

interface AcceptModalProps {
  isModalAcceptOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  request: any;
}

const AcceptModal: React.FC<AcceptModalProps> = ({
  isModalAcceptOpen,
  handleOk,
  handleCancel,
  request
}) => {
  const dispatch = useAppDispatch();
  const initialValues = {
    isRepeated: !!request?.isRepeated,
    appointmentRequestId: request?.id || ""
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (value: any) => {
      handleSubmit(value);
    }
  });
  const { setFieldValue } = formik;

  const handleSubmit = async (value: {
    isRepeated: boolean;
    appointmentRequestId: number;
  }) => {
    const res: any = await dispatch(
      approveAppointmentRequests({ data: { ...value, isRepeated: Number(value.isRepeated) } })
    );
    if (res?.error) {
      message.error("Phê duyệt khách hàng đặt lịch thất bại");
    } else {
      message.success("Đã phê duyệt khách hàng đặt lịch thành công");
      handleOk();
    }
  };

  return (
    <AcceptModalStyle
      title="Xác nhận lịch hẹn"
      visible={isModalAcceptOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="accept-modal"
    >
      <p className="text-content">Bạn xác nhận đồng ý với lịch hẹn này?</p>
      <div className="box-info">
        <div className="request-date">
          <p className="label">Ngày đặt luận giải</p>
          <span className="value">
            {formatDateDDMMYYYY(request.requestDate)}
          </span>
        </div>
        <div className="request-time">
          <p className="label">Thời gian</p>
          <span className="value">
            {WORK_RANGE.find((i) => i.value === request?.requestTime)?.label ||
              ""}
          </span>
        </div>
      </div>
      <Form
        name="isRepeated"
        className="all-year-form"
        layout="vertical"
        onFinish={() => formik.handleSubmit()}
      >
        <div className="box-switch">
          <SwitchField
            field={formik.getFieldProps("isRepeated")}
            className="form-control form-all-year"
            onChange={() => {
              void setFieldValue("isRepeated", !formik.values.isRepeated);
            }}
          />
          <span>Xác nhận chuyển đổi lịch hẹn ngày thành lịch hẹn hằng năm</span>
        </div>
        <div className="button-footer">
          <Button buttonType="cancel" onClick={handleCancel} width={100}>
            Đóng
          </Button>
          <Button htmlType="submit" width={100}>
            Đồng ý
          </Button>
        </div>
      </Form>
    </AcceptModalStyle>
  );
};

export default AcceptModal;
