import { createAsyncThunk } from '@reduxjs/toolkit';

import AUTH_API from './auth.api';
import { setUserData } from 'src/utils';

export const loginAction = createAsyncThunk<any, Auth.LoginRequestPayload>(
  'auth',
  async ({ data, callback = () => { } }, { dispatch, rejectWithValue }) => {
    try {
      const user = await AUTH_API.loginAPI(data);

      if (user?.accessToken) {
        setUserData({accessToken: user.accessToken, email: data.email})
        callback();
      }

      return user;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getMeAction = createAsyncThunk<any>('me', async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await AUTH_API.getMeAPI();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});
