/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";
import { Avatar, Form, message, Skeleton } from "antd";
import { ChatDetailStyle, FormEditerStyle } from "./ChatSectionStyle";
import { QuillEditor } from "src/components/form";
import { useFormik } from "formik";
import { dateTimeForChat } from "src/utils";
import { useAppDispatch, useAppSelector } from "src/stores";
import {
  getConsultationsAction,
  createConsultationAction
} from "src/stores/screens/consultation/consultation.action";
import { LOCAL_STORAGE_KEY } from "src/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useMatch } from "react-router-dom";
import { SpinStyle } from "src/components/style";

interface ChatDetailProps {
  id?: string;
}

const ChatDetail: React.FC<ChatDetailProps> = ({ id }) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const infiniteScrollRef = useRef(null);
  const [height, setHeight] = useState(0);
  const dispatch = useAppDispatch();
  const { messages, isLoading } = useAppSelector((state) => state.consultation);
  const email = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL);
  const location = useLocation();

  const getConsultationDetail = async (params: any = { page: 1 }) => {
    await dispatch(
      getConsultationsAction({ id: id, params: { page: params.page } })
    );
  };

  useEffect(() => {
    if (id) {
      void getConsultationDetail({ id: id, page: 1 });
    }
  }, [id]);

  let intervalId: any;
  const match = useMatch("/consultation/:id");
  const loadIntervalMessage = () => {
    getConsultationDetail({ page: 1 });
  };
  useEffect(() => {
    if (match) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      intervalId = setInterval(loadIntervalMessage, 30000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [location.pathname]);

  const initialValues = {
    chatBox: ""
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (value) => {
      await onSubmit(value.chatBox);
    }
  });
  const { setFieldValue } = formik;

  useEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setHeight(divRef.current.offsetHeight);
      }
    };
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
      updateHeight();
    }
    return () => {
      if (divRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  const onCreateConsultationSuccess = async () => {
    if (id) {
      await getConsultationDetail(id);
      if (lastMessageRef.current) {
        lastMessageRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    }
    await setFieldValue("chatBox", "");
  };

  const onSubmit = async (value: string) => {
    if (id) {
      // use regex check type message
      const res: any = await dispatch(
        createConsultationAction({
          data: { content: value, appointmentId: id },
          callback: onCreateConsultationSuccess
        })
      );
      if (res?.error) {
        message.error("Đã có lỗi xảy ra");
      }
    }
  };

  const loadMoreData = async () => {
    await getConsultationDetail({ id: id, page: Number(messages.page) + 1 });
  };

  return (
    <ChatDetailStyle height={height}>
      <div
        className="chat-content"
        id="scrollableDiv"
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse"
        }}
      >
        <InfiniteScroll
          ref={infiniteScrollRef}
          dataLength={messages?.data?.length}
          next={loadMoreData}
          style={{ display: "flex", flexDirection: "column-reverse" }}
          inverse={true}
          hasMore={messages?.data?.length < messages?.total}
          loader={<Skeleton paragraph={{ rows: 1 }} />}
          endMessage={null}
          scrollableTarget="scrollableDiv"
        >
          <SpinStyle
            spinning={isLoading}
            wrapperClassName="root-spin"
            className="root-spin-component"
          >
            {messages?.data?.map((message, index) => (
              <div
                key={message.id}
                className={`message-item ${
                  message?.sender?.email === email ? "" : "active"
                }`}
                ref={
                  index === messages?.data.length - 1 ? lastMessageRef : null
                }
              >
                <Avatar />
                <div className="wrap-content">
                  <div className="wapper">
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: message.content ?? ""
                      }}
                    />
                    <div className="timestamp">
                      {dateTimeForChat(message.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </SpinStyle>
        </InfiniteScroll>
        <FormEditerStyle ref={divRef}>
          <Form
            name="login"
            className="chat-box-form"
            layout="vertical"
            onFinish={() => formik.handleSubmit()}
          >
            <QuillEditor
              // label="chatBox"
              field={formik.getFieldProps("chatBox")}
              setFieldValue={setFieldValue}
              className="form-control chat-box"
              touched={formik.touched.chatBox}
              placeholder="Nhập văn bản..."
              disable={isLoading}
            />
          </Form>
        </FormEditerStyle>
      </div>
    </ChatDetailStyle>
  );
};

export default ChatDetail;
