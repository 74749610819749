import styled from 'styled-components';
import { Modal } from "src/components"

const AcceptModalStyle = styled(Modal)`
  &.accept-modal {
    .text-content {
      margin-bottom: 10px;
      font-size: 16px;
      color: #ffffff;
    }
    .box-info {
      display: flex;
      gap: 50px;
      .label {
        color: #ffffff;
        margin-bottom: 5px;
      }
      .value {
        color: #EAD38E;
      }
    }
    .box-switch {
      display: flex;
      gap: 10px;
      height: 100%;
      margin-top: 20px;
      span {
        margin: auto 0;
        color: #ffffff
      }
      .ant-form-item {
        margin: auto 0;
      }
      .ant-switch {
        background: #6A6A6A;
      }
      .ant-switch-checked {
        background: #EAD38E;
      }
    }
  }
`;

export default AcceptModalStyle;
