import { get } from 'lodash';
import { AxiosClient } from 'src/configs/axios/axios';

const APPOINTMENT_API = {
  listAppointmentsAPI: async (params: any) => {
    const response = await new AxiosClient().get(`/appointment?${new URLSearchParams(params).toString()}`);
    return get(response, 'data', null);
  },

  updateAppointmentStatusAPI: async (params: any) => {
    const response = await new AxiosClient().put(`/appointment/${params.id}/status`, params);
    const data = get(response, 'data', null);
    return data;
  },

  listAppointmentsTypeDateAPI: async (params: any) => {
    return await new AxiosClient().get(`/appointment/type-date?${new URLSearchParams(params).toString()}`);
  },

  listAppointmentsTypeYearAPI: async (params: any) => {
    return await new AxiosClient().get(`/appointment/type-year?${new URLSearchParams(params).toString()}`);
  },

  listAppointmentsByNewMessageAPI: async (params: any) => {
    return await new AxiosClient().get(`/appointment/by-new-message?${new URLSearchParams(params).toString()}`);
  },
};

export default APPOINTMENT_API;
