import React from "react";
import { Col, Form, Row, message } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  RadioGroupField,
  InputField,
  PasswordField,
  SelectField
} from "src/components/form";
import { Button, Image } from "src/components";
import FormModalStyle from "./FormModalStyle";
import { ATTRIBUTES, GENDER, DAY, MONTH } from "src/constants";
import { DEFAULT_AVATAR } from "src/assets/images";

import { useAppDispatch } from "src/stores";
import {
  createUser,
  updateUser,
  addMissingAttribute,
  deleteMissingAttribute
} from "src/stores/screens/user/user.action";
import { getLunarYearName } from "src/utils";

interface FormModalProps {
  isModalFormOpen: boolean;
  user: User.UserType;
  handleCancel: () => void;
  handleOk: () => void;
}

const FormModal: React.FC<FormModalProps> = ({
  isModalFormOpen,
  user,
  handleCancel,
  handleOk
}) => {
  const dispatch = useAppDispatch();

  const objValidation = {
    username: yup.string().required("Họ tên là bắt buộc"),
    email: yup.string().email().required("Email là bắt buộc"),
    phoneNumber: yup
      .string()
      .required("Số điện thoại là bắt buộc")
      .matches(/^\d+$/, "Số điện thoại không hợp lệ")
  };
  const validationSchema = yup.object().shape(
    user?.id
      ? objValidation
      : {
          ...objValidation,
          password: yup.string().required("Mật khẩu là bắt buộc").min(8)
        }
  );

  const [year, month, day] = (user?.dateOfBirthLunar || "")
    .trim()
    .split(/\s*-\s*/);

  const initialValues = {
    id: user?.id,
    username: user?.username || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    password: user?.password || "",
    dateOfBirthLunar: user?.dateOfBirthLunar || "",
    attribute: user?.attribute || null,
    gender: user?.gender || 0,
    missingAttributes: user?.missingAttributes || [],
    dateLunar: day || null,
    monthLunar: month || null,
    yearLunar: year || null,
    missAttributes: user?.missAttributes || []
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleSubmit(value);
    }
  });

  const handleSubmit = async (value: User.UserType) => {
    const params = {
      ...value,
      dateOfBirthLunar: `${value.yearLunar}-${value.monthLunar}-${value.dateLunar}`,
      role: "user"
    };
    const result: any = user.id
      ? await dispatch(updateUser({ data: params }))
      : await dispatch(createUser({ data: params }));
    if (result?.error) {
      message.error(
        user.id ? "Cập nhật người dùng thất bại" : "Tạo mới người dùng thất bại"
      );
    } else {
      formik.resetForm({});
      handleOk();
      message.success(
        user.id
          ? "Cập nhật người dùng thành công"
          : "Tạo mới người dùng thành công"
      );
    }
  };

  const onClose = () => {
    formik.resetForm({});
    handleCancel();
  };

  const currentYear: number = new Date().getFullYear();
  const lunarYearOptions: Array<{ value: string; label: string }> = Array.from(
    { length: 100 },
    (_, i) => currentYear - i
  ).map((year: number) => ({
    value: String(year),
    label: `${getLunarYearName(year)} (${year})`
  }));

  const onSelectMissingAttribute = async (value: any) => {
    if (user?.id) {
      const params = { userId: user.id, attribute: value }
      await dispatch(addMissingAttribute({ data: params }))
    }
  };

  const onClearMissingAttribute = async (value: any) => {
    if (user?.id) {
      const params = { userId: user.id, attribute: value }
      await dispatch(deleteMissingAttribute({ data: params }))
    }
  };

  const { setFieldValue } = formik;

  return (
    <FormModalStyle
      title={user.id ? "Cập nhật người dùng" : "Tạo người dùng"}
      visible={isModalFormOpen}
      onCancel={onClose}
      className="modal-form"
      width={800}
    >
      <Form
        name="login"
        className="setting-form"
        layout="vertical"
        onFinish={() => {
          formik.handleSubmit();
        }}
      >
        <Row>
          <Col className="col-avatar" span={8}>
            <Image src={DEFAULT_AVATAR} width={190} height={190} />
          </Col>
          <Col className="col-form" span={16}>
            <InputField
              label="Họ và tên"
              field={formik.getFieldProps("username")}
              setFieldValue={setFieldValue}
              className="form-control form-username"
              inputProps={{
                size: "middle",
                placeholder: "Nhập họ và tên"
              }}
              error={formik.errors.username}
              touched={formik.touched.username}
              required
            />
            <InputField
              label="Email"
              field={formik.getFieldProps("email")}
              setFieldValue={setFieldValue}
              className="form-control form-email"
              inputProps={{
                size: "middle",
                placeholder: "Nhập email"
              }}
              error={formik.errors.email}
              touched={formik.touched.email}
              required
            />
            <InputField
              label="Số điện thoại"
              field={formik.getFieldProps("phoneNumber")}
              setFieldValue={setFieldValue}
              className="form-control form-phoneNumber"
              inputProps={{
                size: "middle",
                placeholder: "Nhập số điện thoại"
              }}
              error={formik.errors.phoneNumber}
              touched={formik.touched.phoneNumber}
              required
            />
            <div className="birthdayLunar">
              <p className="label">Ngày sinh âm lịch</p>
              <div className="box-birthday">
                <SelectField
                  optionsSelect={DAY.map((item) => ({
                    value: item,
                    label: item
                  }))}
                  field={formik.getFieldProps("dateLunar")}
                  setFieldValue={setFieldValue}
                  className="form-control birthdayLunar-item date-lunar"
                  placeholder="Chọn Ngày"
                />
                <SelectField
                  optionsSelect={MONTH.map((item) => ({
                    value: item,
                    label: item
                  }))}
                  field={formik.getFieldProps("monthLunar")}
                  setFieldValue={setFieldValue}
                  className="form-control birthdayLunar-item month-lunar"
                  placeholder="Chọn Tháng"
                />
                <SelectField
                  optionsSelect={lunarYearOptions}
                  field={formik.getFieldProps("yearLunar")}
                  setFieldValue={setFieldValue}
                  className="form-control birthdayLunar-item year-lunar"
                  placeholder="Chọn Năm"
                />
              </div>
            </div>
            <RadioGroupField
              label="Giới tính"
              field={formik.getFieldProps("gender")}
              setFieldValue={setFieldValue}
              className="form-control form-gender"
              error={formik.errors.gender}
              touched={formik.touched.gender}
              optionsSelect={GENDER}
              required
            />
            <SelectField
              label="Mệnh"
              optionsSelect={ATTRIBUTES}
              field={formik.getFieldProps("attribute")}
              setFieldValue={setFieldValue}
              className="form-control form-attribute"
              error={formik.errors.attribute}
              touched={formik.touched.attribute}
              placeholder="Chọn Mệnh"
            />
            {!!user?.id && <SelectField
              label="Thiếu Mệnh"
              mode="tags"
              optionsSelect={ATTRIBUTES}
              field={formik.getFieldProps("missAttributes")}
              setFieldValue={setFieldValue}
              className="form-control form-missing-attribute"
              placeholder="Chọn Thiếu Mệnh"
              callbackOnSelect={onSelectMissingAttribute}
              callbackOnDeselect={onClearMissingAttribute}
            />}
            <PasswordField
              label="Mật khẩu"
              field={formik.getFieldProps("password")}
              setFieldValue={setFieldValue}
              className="form-control form-password"
              inputProps={{
                size: "middle",
                placeholder: "Mật khẩu"
              }}
              error={formik.errors.password}
              touched={formik.touched.password}
              required={!user?.id}
            />
          </Col>
        </Row>
        <div className="button-footer">
          <Button buttonType="cancel" onClick={onClose} width={100}>
            Đóng
          </Button>
          <Button htmlType="submit" width={100}>
            {user.id ? "Cập nhật" : "Tạo mới"}
          </Button>
        </div>
      </Form>
    </FormModalStyle>
  );
};

export default FormModal;
