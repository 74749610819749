import React from "react";
import { Col, Form, Row, message } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import { SelectField, DatepickerField } from "src/components/form";
import { Button } from "src/components";
import FormModalStyle from "./FormModalStyle";
import { WORK_RANGE } from "src/constants";

import { useAppDispatch } from "src/stores";
import { createSetting } from "src/stores/screens/setting/setting.action";

interface FormModalProps {
  isModalFormOpen: boolean;
  user: User.UserType;
  handleCancel: () => void;
  handleOk: () => void;
}

const FormModal: React.FC<FormModalProps> = ({
  isModalFormOpen,
  handleCancel,
  handleOk,
}) => {
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    busyDate: yup.string().required("Ngày bắt đầu là bắt buộc"),
    endDate: yup.string().when("busyDate", (busyDate, schema) => {
      return (
        busyDate &&
        schema
          .test({
            name: "end-date-after-start",
            exclusive: true,
            message: "Ngày kết thúc phải sau ngày bắt đầu",
            test: function (endDate) {
              if (!endDate) {
                return true;
              }
              return new Date(endDate) > new Date(busyDate);
            },
          })
          .test({
            name: "check-dates",
            exclusive: true,
            message: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
            test: function (startDate) {
              const endDate = this.resolve(yup.ref("endDate"));
              if (!endDate) {
                return true;
              }
              const finishDate = new Date(endDate);
              return new Date(startDate) <= finishDate;
            },
          })
      );
    }),
    busyTimeFrame: yup
      .string()
      .nullable()
      .required("Thời gian khung giờ là bắt buộc"),
  });

  const initialValues = {
    busyDate: "",
    endDate: "",
    busyTimeFrame: null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (value) => {
      handleSubmit(value);
    },
  });

  const onRegisterSuccess = (): void => {
    formik.resetForm({});
    message.success("Đăng ký lịch bận thành công!");
    handleOk();
  };

  const handleSubmit = async (value: any) => {
    let dataNew = value;
    dataNew = {
      ...dataNew,
      endDate: value?.endDate ? value?.endDate : value?.busyDate,
    };
    void dispatch(
      createSetting({ data: dataNew, callback: onRegisterSuccess })
    );
  };

  const onClose = () => {
    formik.resetForm({});
    handleCancel();
  };

  const { setFieldValue } = formik;

  return (
    <FormModalStyle
      title={"Thêm lịch bận"}
      visible={isModalFormOpen}
      onCancel={onClose}
      className="modal-form"
      width={500}
    >
      <Form
        name="login"
        className="setting-form"
        layout="vertical"
        onFinish={() => {
          formik.handleSubmit();
        }}
      >
        <Row>
          <Col span={24}>
            <DatepickerField
              label="Ngày bắt đầu"
              field={formik.getFieldProps("busyDate")}
              setFieldValue={setFieldValue}
              className="form-control form-busyDate"
              error={formik.errors.busyDate}
              touched={formik.touched.busyDate}
              placeholder="Ngày bắt đầu"
              required
            />
            <DatepickerField
              label="Ngày kết thúc"
              field={formik.getFieldProps("endDate")}
              setFieldValue={setFieldValue}
              className="form-control form-endDate"
              error={formik.errors.endDate}
              touched={formik.touched.endDate}
              placeholder="Ngày kết thúc"
            />
            <SelectField
              label="Thời gian"
              optionsSelect={WORK_RANGE}
              field={formik.getFieldProps("busyTimeFrame")}
              setFieldValue={setFieldValue}
              className="form-control form-missing-attribute"
              error={formik.errors.busyTimeFrame}
              touched={formik.touched.busyTimeFrame}
              placeholder="Chọn thời gian"
              required
            />
          </Col>
        </Row>
        <div className="button-footer">
          <Button buttonType="cancel" onClick={onClose} width={100}>
            Đóng
          </Button>
          <Button htmlType="submit" width={100}>
            Tạo mới
          </Button>
        </div>
      </Form>
    </FormModalStyle>
  );
};

export default FormModal;
