import Media from 'src/utils/media';
import styled from 'styled-components';

export const ConsultationStyle = styled.div`
  .content-right {
    border-left: 1px solid #4e473e;
  }

  .btn-submit {
    display: flex;
    justify-content: flex-end;
  }

  .text-white {
    font-size: 16px;
    color: #ffffff;
  }
  .custom-card {
    .ant-card-body {
      padding: 0px;
    }
  }
  .ant-card-body {
    .content-chat {
      height: calc(100vh - 61px - 300px);
    }
  }

  .info-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .box-user {
      margin: auto 0;
      display: flex;
      gap: 10px;
      .btn-back, .avatar, .username {
        margin: auto 0;
        color: #EAD38E;
      }
      .btn-back {
        cursor: pointer;
      }
      .username {
        font-size: 16px;
      }
    }
    .status {
      margin: auto 0;
      color: #EAD38E;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      ${Media.lessThan(Media.SIZE.MD)} {
        margin-top: 10px;
        text-align: left;
      }
    }
    .btn-status {
      display: flex;
      justify-content: end;
      margin: auto 0;
    }
  }
`;

